import React, { useState, useEffect, useContext } from "react";
import { Autocomplete, Box, Container, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import PicUploadMultiple from "../../common/upload/PicUploadMultiple";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router";

export default function BakerPhotos(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [tag, setTag] = useState("");
  const [products, setProducts] = useState([]);

  const [allTags, setAllTags] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi("/tags/product").then((res) => {
      setAllTags(res.data.tags);
    });
  }, []);

  const getPhotos = (append) => {
    bkstApi(`/my/photo?tag=${tag}`).then((res) => {
      setProducts(res.data.products);
      setLoading(false);
    });
  };

  const addPhotos = (images) => {
    setLoading(true);
    bkstApi.post(`/my/photos`, { images }).then((res) => {
      setProducts((arr) => [...res.data.photos, ...products]);
      setLoading(false);
      navigate(0);
    });
  };

  useEffect(() => {
    getPhotos();
  }, [tag]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box my={6}>
          <Box mb={4}>
            <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
              <Box textAlign={"left"}>
                <Typography variant="h1" gutterBottom>
                  Photos
                </Typography>
              </Box>
              <Box>
                <PicUploadMultiple id="add-photos" title="Add" onComplete={(images) => addPhotos(images)} folder={`img/p`} />
              </Box>
            </Box>
            <Typography color="textSecondary" variant="body1">
              These photos will show up in the Photo Gallery on your website. They will also show up when customers search for custom designs on your
              website.
            </Typography>
          </Box>
          <Box my={2}>
            <Autocomplete
              value={tag}
              onChange={(e, v) => setTag(v || "")}
              options={allTags}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} variant="outlined" label={"Filter by Tag"} placeholder="Filter by Tag" />}
              freeSolo
              blurOnSelect
            />
          </Box>
          <Grid container spacing={3}>
            {products &&
              products.map((value, index) => {
                return (
                  <Grid item xs={12} sm={4} key={index} align="center">
                    <Box>
                      <img src={value.img} style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} />
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Container>
    );
  }
}
