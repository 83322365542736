import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Alert,
  Badge,
  Box,
  Chip,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  IconButton,
  Paper,
  Typography,
  TextField,
  LinearProgress,
  Tab,
  Tabs
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { UserContext } from "../../../context/UserContext";
import { getItemBasePrice, getItemMetadata, getItemPrice } from "../../util";
import bkstApi from "../../../api";
import ProductVariantSelection from "../../../product/component/ProductVariantSelection";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../constants";
import currency from "currency.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import ImageCarousel from "../ImageCarousel";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Quantity from "../Quantity";
import EditIcon from "@mui/icons-material/Edit";
import { Link as RouterLink } from "react-router-dom";

export default function StandardLineItemEntryPopup(props) {
  const { user } = useContext(UserContext);
  const ref = useRef();

  const { mode, immutablePrice, idx } = props;

  const [query, setQuery] = useState("");

  const [products, setProducts] = useState([]);
  const [categoryMap, setCategoryMap] = useState("");

  const [product, setProduct] = useState("");
  const [item, setItem] = useState(props.item || "");

  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);

      if (!props.item?.productId) {
        bkstApi(`/my/product?filter=active`).then((res) => {
          let sortedByRank = res.data.products.sort((o1, o2) => {
            let rslt = (+o2.rank || 0) - (+o1.rank || 0);
            if (rslt === 0) {
              rslt = o1.title > o2.title ? 1 : -1;
            }
            return rslt;
          });
          setProducts(sortedByRank);

          let map = {};
          for (const p of sortedByRank) {
            const cat = p.type.toLowerCase();
            const arr = map[cat] || [];
            arr.push(p);
            map[cat] = arr;
          }
          setCategoryMap(map);

          setLoading(false);
        });
      } else {
        bkstApi(`/my/product/${props.item.productId}`).then((res) => {
          setProduct(res.data);
          setLoading(false);
        });
      }
    }
  }, [open]);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [item.productId]);

  useEffect(() => {
    if (item.variant) {
      const variantPrice = getItemPrice(item.variant, getItemBasePrice(item.variant));
      const total = currency(variantPrice).multiply(item.quantity).value;
      setItem({ ...item, price: variantPrice, total });
    }
  }, [item.variant, item.quantity]);

  const sortCategories = (o1, o2) => {
    if (o1.includes("featured")) {
      return -1;
    } else if (o2.includes("featured")) {
      return 1;
    } else {
      return o1.localeCompare(o2);
    }
  };

  const scrollToCategory = (cat) => {
    const el = document.getElementById(cat);
    if (el) {
      el.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  const handleProductChange = (product) => {
    setProduct(product);
    setItem({
      productId: product.id,
      images: [product.images[0]],
      title: product.title,
      type: product.type,
      price: "",
      quantity: 1,
      includeFees: true
    });
  };

  const validate = (item) => {
    let arr = [];
    if (!item.type?.trim()) {
      arr.push("Item Type is required");
    }
    if (item.type === "Other" && !item.title?.trim()) {
      arr.push("Title is required");
    }
    if (!(+item.price > 0)) {
      arr.push("Variant is required");
    }
    if (!(+item.quantity > 0)) {
      arr.push("Quantity is required");
    }

    return arr;
  };

  const handleDelete = () => {
    props.onChange("", "delete");
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const arr = validate(item);
    setErrors(arr);
    if (arr.length > 0) {
      //ref.current?.scrollIntoView();
      return;
    } else {
      props.onChange(item);
      handleClose();
    }
  };

  const onBackButtonClick = () => {
    if (mode === "add") {
      if (item) {
        setItem("");
      } else {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setItem("");
    setQuery("");
    setErrors([]);
  };

  return (
    <>
      {mode === "add" ? (
        <Button
          disableElevation
          startIcon={<AddIcon />}
          variant={"contained"}
          color={props.idx === 0 ? "primary" : "secondary"}
          onClick={() => {
            setOpen(true);
          }}
          fullWidth
        >
          STANDARD ITEM
        </Button>
      ) : (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setItem(props.item);
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      )}
      {open && (
        <Dialog fullScreen={true} open={open} scroll="paper">
          <DialogTitle
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0",
              backgroundColor: "#DEDEDE",
              borderBottom: "3px solid #DEDEDE"
            }}
          >
            <IconButton onClick={onBackButtonClick}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">{item ? item.title : "Pick an Item"}</Typography>
            <Box>
              {/*item && (
                <IconButton type="submit" color="primary">
                  <DoneIcon />
                </IconButton>
              )*/}
            </Box>
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#F7F7F7" }}>
            <form onSubmit={handleSubmit}>
              {loading && <LinearProgress />}
              <Box pt={4} pb={20} ref={ref}>
                {item ? (
                  <Grid container>
                    <Grid item lg={2} md={1} sm={0} xs={0}></Grid>
                    <Grid item lg={8} md={10} sm={12} xs={12}>
                      <Box>
                        <Grid container spacing={4}>
                          <Grid item sm={5} xs={6}>
                            <Box sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                              <ImageCarousel images={item.images} />
                            </Box>
                          </Grid>
                          <Grid item sm={7} xs={12}>
                            {product && (
                              <Box>
                                <Typography variant="overline" color="textSecondary">
                                  Variant
                                </Typography>
                                <Paper elevation={0}>
                                  <Box p={2} pt={4}>
                                    <ProductVariantSelection
                                      variant={item.variant || ""}
                                      options={product.options}
                                      onChange={(variant) => setItem({ ...item, variant })}
                                    />
                                  </Box>
                                </Paper>
                              </Box>
                            )}
                            <Box my={3}>
                              <Typography variant="overline" color="textSecondary">
                                Customization
                              </Typography>
                              <Paper elevation={0}>
                                <Box p={2} pt={4}>
                                  <TextField
                                    id="description"
                                    value={item.description}
                                    label="Notes (optional)"
                                    name="description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    onChange={(e) => setItem({ ...item, description: e.target.value })}
                                  />
                                </Box>
                              </Paper>
                            </Box>
                            <Box my={3}>
                              <Typography variant="overline" color="textSecondary">
                                Amount
                              </Typography>
                              <Paper elevation={0}>
                                <Box p={2} pt={4}>
                                  <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
                                    <Quantity value={item.quantity} onChange={(val) => setItem({ ...item, quantity: val })} />
                                    {+item.total > 0 && <Typography variant="h5">{currency(item.total).format()}</Typography>}
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                            {errors.length > 0 && (
                              <Box mb={2} sx={{ display: "block" }}>
                                <Alert severity="error">{errors.join(". ")}</Alert>
                              </Box>
                            )}
                            <Button color="primary" variant="contained" fullWidth type="submit">
                              SUBMIT
                            </Button>
                            {mode !== "add" && !immutablePrice && (
                              <Box mt={5}>
                                <Button color="error" onClick={handleDelete} fullWidth>
                                  Delete This Item
                                </Button>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Box>
                    {products.length > 0 && (
                      <Box>
                        <Box
                          sx={{
                            position: "-webkit-sticky",
                            position: "sticky",
                            top: "0",
                            border: "1px solid #EEE",
                            zIndex: "1000",
                            backgroundColor: "#FFF"
                          }}
                        >
                          <Box>
                            <TextField
                              id="query"
                              value={query}
                              label=""
                              name="query"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => setQuery(e.target.value.toLowerCase())}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment>
                                    <SearchOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment>
                                    {query && (
                                      <IconButton onClick={(e) => setQuery("")}>
                                        <CloseOutlinedIcon />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Box>
                          {!query && (
                            <Box sx={{ backgroundColor: "#ECECEC" }}>
                              <Tabs value={false} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                {Object.keys(categoryMap)
                                  .sort(sortCategories)
                                  .map((cat, idx) => (
                                    <Tab key={idx} sx={{ fontSize: ".75rem" }} label={cat} onClick={() => scrollToCategory(cat)} />
                                  ))}
                              </Tabs>
                            </Box>
                          )}
                        </Box>
                        <Box my={4}>
                          {!query && (
                            <Box>
                              {Object.keys(categoryMap)
                                .sort(sortCategories)
                                .map((key) => {
                                  return (
                                    <Box mb={4} id={key} sx={{ scrollMarginTop: "75px" }}>
                                      <Box textAlign={"left"}>
                                        <Typography variant="overline">{key}</Typography>
                                      </Box>
                                      <Grid container spacing={3}>
                                        {categoryMap[key].map((o, index) => {
                                          const metadata = getItemMetadata(o);

                                          return (
                                            <Grid item xl={1} lg={2} md={3} sm={4} xs={6} sx={{ alignSelf: "stretch" }}>
                                              <Link
                                                color="inherit"
                                                underline="never"
                                                sx={{ cursor: "pointer", textAlign: "center", height: "100%" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  handleProductChange(o);
                                                }}
                                              >
                                                <Badge
                                                  sx={{
                                                    "& .MuiBadge-badge": {
                                                      right: 20,
                                                      top: 10
                                                    }
                                                  }}
                                                  badgeContent={
                                                    <Chip
                                                      sx={{
                                                        height: "16px",
                                                        fontSize: "11px",
                                                        color: "#555",
                                                        backgroundColor: "#FFF",
                                                        border: "1px solid #DDD"
                                                      }}
                                                      size="small"
                                                      label={currency(metadata.price.min, { precision: 0 }).format()}
                                                    ></Chip>
                                                  }
                                                >
                                                  <img
                                                    src={o.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                                                    style={{ width: "100%", aspectRatio: "1", objectFit: "cover", border: "1px solid #ECECEC" }}
                                                  />
                                                </Badge>
                                                <Box pt={.5}>
                                                  <Typography variant="body1" color="textSecondary">
                                                    {o.title}
                                                  </Typography>
                                                </Box>
                                              </Link>
                                            </Grid>
                                          );
                                        })}
                                      </Grid>
                                    </Box>
                                  );
                                })}
                            </Box>
                          )}
                        </Box>
                        {query && (
                          <Grid container spacing={4}>
                            {products
                              .filter((o) => o.title.toLowerCase().includes(query))
                              .map((o, index) => {
                                const metadata = getItemMetadata(o);

                                return (
                                  <Grid item md={2} sm={2} xs={6} sx={{ alignSelf: "stretch" }}>
                                    <Link
                                      color="inherit"
                                      underline="never"
                                      sx={{ cursor: "pointer", textAlign: "center", height: "100%" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleProductChange(o);
                                      }}
                                    >
                                      <img
                                        src={o.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`}
                                        style={{ width: "100%", aspectRatio: "1", objectFit: "cover", border: "1px solid #ECECEC" }}
                                      />
                                      <Box>
                                        <Typography variant="body2" color="textSecondary">
                                          {o.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontWeight: "600" }} display="block">
                                          {currency(metadata.price.min).format()}
                                        </Typography>
                                      </Box>
                                    </Link>
                                  </Grid>
                                );
                              })}
                          </Grid>
                        )}
                      </Box>
                    )}
                    {!loading && products.length === 0 && (
                      <Container maxWidth="sm">
                        <Box py={4} textAlign={"center"}>
                          <Typography variant="h5" color="error" gutterBottom>
                            No Active Items Found!
                          </Typography>
                          <Typography variant="body1" color="textSecondary" gutterBottom>
                            Click the button below to view your Online Shop & add one or more items. Add everyday, seasonal & limited-time items to
                            make it easier to order.
                          </Typography>
                          <Box my={3}>
                            <Button variant="contained" component={RouterLink} to={`/shop`}>
                              View Online Shop
                            </Button>
                          </Box>
                        </Box>
                      </Container>
                    )}
                  </Box>
                )}
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
