import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import IconText from "../../common/IconText";
import moment from "moment";
import { CheckOutlined } from "@mui/icons-material/";
import currency from "currency.js";
import CloseIcon from "@mui/icons-material/Close";

export default function QuoteFulfillment(props) {
  const { quote } = props;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography variant="overline" color="textSecondary">
            Date
          </Typography>
          <Box py={0.5}>
            <Typography variant="body2">
              {moment(quote.fulfillment.date).format("ddd, MMM D, YYYY")} {quote.fulfillment.time ? ` [${quote.fulfillment.time}]` : ""}
            </Typography>
            <Typography variant="body2">{quote.fulfillment.time ? ` [${quote.fulfillment.time}]` : ""}</Typography>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="overline" color="textSecondary">
            Fulfillment Options
          </Typography>
          <Box py={0.5}>
            <IconText icon={CheckOutlined} text={"Store Pickup"} variant="body2" />
          </Box>
          {quote.fulfillment.option.delivery.type === "in-house" && quote.fulfillment.option.delivery.location && (
            <IconText
              icon={CheckOutlined}
              text={`In-house Delivery: ${currency(quote.fulfillment.option.delivery.total).format()} to Zip Code (${
                quote.fulfillment.option.delivery.location.zip
              })`}
              variant="body2"
            />
          )}
          {quote.fulfillment.option.delivery.type === "metrobi" && <IconText icon={CheckOutlined} text={"Metrobi Delivery"} variant="body2" />}
          {quote.fulfillment.option.delivery.type === "no" && <IconText icon={CloseIcon} text={"Delivery"} variant="body2" />}
        </Grid>
      </Grid>
    </Box>
  );
}
