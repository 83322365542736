import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from "@mui/material";
import bkstApi from "../../../api";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChatCanvas from "../../../common/chat/ChatCanvas";
import NewMessage from "../../../common/chat/NewMessage";
import Slide from "@mui/material/Slide";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ChatHeader from "../ChatHeader";
import { useNavigate } from "react-router";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} mountOnEnter unmountOnExit timeout={4000} {...props} />;
});

export default function BakerChat(props) {
  const navigate = useNavigate();

  const { request, quote } = props;
  const ref = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(window.location.hash.includes("chat"));
  const [status, setStatus] = useState("");
  const [chat, setChat] = useState(props.chat || []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      bkstApi(`/my/quote/${request.id}/chat`).then((res) => {
        setChat(res.data.chat);
        setStatus(res.data.status);
        setLoading(false);

        // remove hash
        window.history.replaceState("", "", `${window.location.pathname}`);
      });
    }
  }, [open]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: "end" });
    }
  }, [chat]);

  const extendExpiration = () => {
    bkstApi.put(`/my/quote/${request.id}/exdate`, {}).then((res) => {
      bkstApi(`/my/quote/${request.id}`).then((res) => {
        navigate(0);
      });
    });
  };

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  const lastMsg = chat.filter((o) => o.by === "cust");
  const lastMsgTs = lastMsg && lastMsg.length > 0 && lastMsg[lastMsg.length - 1].ts;
  console.log(lastMsgTs);

  let footer = (
    <NewMessage handleNewMsg={handleNewMsg} uploadFolder={`img/r/${request.id}`} postUrl={`/request/${request.id}/chat`} lastMsgTs={lastMsgTs} />
  );
  if (status === "paid") {
    footer = <Alert severity="info">This chat session has ended. Please visit the order page to continue the conversation.</Alert>;
  }

  return (
    <span>
      <IconButton size="small" onClick={(e) => setOpen(true)}>
        <SmsOutlinedIcon />
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={fullScreen ? false : "sm"}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{ backgroundColor: "#ECECEC", margin: "0", paddingLeft: "10px", paddingRight: "5px", paddingTop: "15px", paddingBottom: "10px" }}
        >
          <Box display="flex" alignItems="top">
            <ChatHeader request={request} quote={quote} status={props.status} />
          </Box>
          {/* <IconButton
                color="inherit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={`tel:${props.phone}`}
              >
                <PhoneIcon />
              </IconButton> */}
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon sx={{ color: "#999" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            {loading && <LinearProgress />}
            {!loading && props.status === "pending" && chat.length === 0 && (
              <Box px={2} py={4}>
                <Alert severity="info" icon={<TipsAndUpdatesOutlinedIcon />}>
                  <AlertTitle>Got questions for your customer?</AlertTitle>
                  Turn questions into options with prices to save time and boost orders. E.g., instead of asking what toppers they want, list options
                  like fondant figures, toppers, or flowers with prices and let them pick!
                </Alert>
              </Box>
            )}
            <ChatCanvas chat={chat} uid={props.uid} />
            <Box textAlign="center" ref={ref}>
              {props.status === "active" && (
                <Chip size="small" variant="outlined" label="View Quote" color="primary" onClick={() => setOpen(false)} />
              )}
              {props.status === "expired" && <Chip size="small" variant="outlined" label="Extend Quote" color="primary" onClick={extendExpiration} />}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: 0, border: 0, paddingBottom: "10px", paddingRight: "10px", paddingLeft: 0, paddingTop: 0 }}>
          <Box sx={{ flexGrow: "1" }}>{footer}</Box>
        </DialogActions>
      </Dialog>
    </span>
  );
}
