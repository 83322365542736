import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import StaticPage from "../../common/StaticPage";
import BakerQuoteEntry from "../components/BakerQuoteEntry";
import { useNavigate } from "react-router";
import moment from "moment";
import SendQuoteHeader from "../components/SendQuoteHeader";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import QuoteItemGeneric from "../../quote/component/QuoteItemGeneric";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { getCustomerName, getSaasWebsiteUrl, hasManagerAccess, isV2SaaSPlan } from "../../common/util";
import { UserContext } from "../../context/UserContext";
import ShareButton from "../../common/component/ShareButton";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AssistedCheckout from "../../common/component/AssistedCheckout";
import QuoteStatusHeader from "../../quote/component/QuoteStatusHeader";
import CustomerDetailsButton from "../../common/component/CustomerDetailsButton";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import BakerChat from "../../quote/component/action/BakerChat";
import RequestSummary from "../../request/components/RequestSummary";
import { Timeline, TimelineContent, TimelineDot, TimelineConnector, TimelineSeparator } from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import CancelQuote from "../../quote/screen/CancelQuote";
import { ExpandMoreOutlined } from "@mui/icons-material";
import UserAvatar from "../../common/UserAvatar";
import QuoteFulfillment from "../../quote/component/QuoteFulfillment";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";

export default function MyQuote(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();
  const { hash } = useLocation();

  const { reqId } = useParams();
  const [request, setRequest] = useState();
  const [quote, setQuote] = useState();
  const [mode, setMode] = useState("view");
  const [history, setHistory] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    setRequest("");
    setQuote("");
    setMode("view");

    bkstApi(`/my/quote/${reqId}`).then((res) => {
      if (hash === "#chat") {
        // check if order has been placed
        if (res.data?.request?.orderId) {
          navigate(`/order/${res.data.request.orderId}#chat`);
        }
      }

      setRequest(res.data.request);
      setQuote(res.data.quote);
      setMode(res.data.quote.status === "pending" ? "edit" : "view");
      setLoading(false);
    });
  }, [reqId, location.key]);

  const getHistory = () => {
    return bkstApi(`/my/quote/${reqId}/history`).then((res) => {
      let arr = [];
      for (let o of res.data.history) {
        if (!o.updTs) {
          o.updTs = o.ts._seconds * 1000;
        }
        arr.push(o);
      }
      arr.push(quote);
      arr.sort((o1, o2) => +o2.updTs - +o1.updTs);

      setHistory(arr);
    });
  };

  const onSubmit = (payload) => {
    bkstApi
      .put(`/my/quote/${reqId}`, payload)
      .then((res) => {
        setLoading(true);
        navigate(0);
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  };

  if (loading) {
    return <LinearProgress />;
  } else if (!request) {
    return (
      <StaticPage
        title="Closed"
        desc="Sorry, this quote request is no longer available. Either the customer has already placed an order or the request date has passed."
        icon={UpdateOutlinedIcon}
        href="/dashboard"
        btnText="View Dashboard"
      ></StaticPage>
    );
  } else if (request) {
    const isManager = hasManagerAccess(user.role);

    let { status, exdate } = quote;
    let expiration = exdate && exdate.includes("T") ? exdate : `${exdate}T23:59`;

    if (status === "active" && moment(expiration).diff(moment(), "minutes") <= 0) {
      status = "expired";
    } else if (status === "block") {
      status = "unavailable";
    }

    const name = getCustomerName({
      firstName: request.firstName,
      lastName: request.lastName,
      name: request.name
    });

    /*if (user.role === "clerk") {
      const saasDomainUrl = getSaasWebsiteUrl(user.domain);

      return (
        <Container maxWidth="md">
          <Box my={4} textAlign={"center"}>
            <Typography variant="h2" gutterBottom>
              Quote
            </Typography>
            <Typography variant="h5" color="textSecondary">
              {name} ● {moment(request.date).format("ddd, MMM D")}
            </Typography>
          </Box>
          <Box py={4} sx={{ border: "2px solid #DDD" }}>
            <Grid container>
              <Grid item sm={2} xs={12}></Grid>
              <Grid item sm={8} xs={12}>
                <iframe
                  src={`${saasDomainUrl}/quote/${request.id}?su=${user.id}`}
                  style={{ width: "100%", height: "300vh", border: "0" }}
                  scrolling="no"
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      );
    } else {*/
    if (quote.items) {
      return (
        <Container maxWidth="sm">
          <Box my={4}>
            <Box my={4} textAlign={"center"}>
              <QuoteStatusHeader status={status} request={request} quote={quote} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                {request.src === "direct" ? (
                  <CustomerDetailsButton
                    customer={{
                      firstName: request.firstName,
                      lastName: request.lastName,
                      name: request.name,
                      phone: request.phone,
                      email: request.email
                    }}
                    src={request.src}
                  />
                ) : (
                  <CustomerContactInfoButton
                    customer={{
                      firstName: request.firstName,
                      lastName: request.lastName,
                      name: request.name
                    }}
                  />
                )}
              </Box>
              {request.src === "direct" && isManager && (
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center", flexGrow: "1", justifyContent: "flex-end" }}>
                  {quote.status !== "paid" && <ShareButton type="quote" link={`/quote/${reqId}`} />}
                  <BakerChat request={request} quote={quote} status={status} />
                  {quote.status === "paid" && request.orderId && (
                    <IconButton color="secondary" component={RouterLink} to={`/order/${request.orderId}`}>
                      <LocalMallOutlinedIcon />
                    </IconButton>
                  )}
                  {quote.status !== "paid" && (
                    <IconButton color="secondary" component={RouterLink} to={`/quote/${request.id}/edit`}>
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
            <Paper elevation={0}>
              <Box px={2} py={2}>
                {quote.items.map((item, idx) => (
                  <Box key={idx} py={1}>
                    <LineItemV2 item={item} view="min" />
                  </Box>
                ))}
                <Box my={2}>
                  <Divider />
                </Box>
                <QuoteFulfillment quote={quote} />
              </Box>
            </Paper>
            <Box my={6}>
              {request.src === "direct" && request.status !== "paid" && (
                <Accordion elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Other Actions</AccordionSummary>
                  <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                    {isManager && (
                      <Box px={2} py={2}>
                        <CancelQuote request={request} />
                        <Typography variant="caption" color="textSecondary">
                          Canceling a quote removes it from your dashboard. Active quotes are expired & can no longer be booked by customer.
                        </Typography>
                      </Box>
                    )}
                    <Box px={2} py={2}>
                      <Button
                        component={RouterLink}
                        to="/create"
                        state={{
                          customer: {
                            firstName: request.firstName,
                            lastName: request.lastName,
                            name: request.name,
                            phone: request.phone,
                            email: request.email
                          },
                          items: quote.items,
                          fulfillment: { date: quote.fulfillment.date }
                        }}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                      >
                        create cash order
                      </Button>
                      <Typography variant="caption" color="textSecondary">
                        Please note that cash orders are for internal tracking only. They do not track payments, support in-app chat, send automated
                        notifications like order confirmation or solicit online reviews.
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              {isManager && (
                <Accordion
                  elevation={0}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      getHistory();
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>History</AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <Box>
                      {history && (
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0
                            }
                          }}
                        >
                          {history.map((o) => {
                            return (
                              <TimelineItem>
                                <TimelineSeparator>
                                  <UserAvatar uid={o.uid} />
                                  <TimelineConnector />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent mb={4} sx={{ backgroundColor: "#F7F7F7" }}>
                                  <Box py={1}>
                                    <Typography variant="overline" display="block">
                                      {moment(o.updTs).format("LLLL")}
                                    </Typography>
                                    {o.status === "pending" ? (
                                      <Paper elevation={0} my={0.5}>
                                        <Box p={2}>
                                          <RequestSummary request={request} />
                                        </Box>
                                      </Paper>
                                    ) : (
                                      <Paper elevation={0}>
                                        <Box p={2} my={0.5}>
                                          {o.items.map((item, idx) => (
                                            <Box key={idx} py={1}>
                                              <LineItemV2 item={item} view="min" />
                                            </Box>
                                          ))}
                                          <Box my={1}>
                                            <Divider />
                                          </Box>
                                          <QuoteFulfillment quote={o} />
                                        </Box>
                                      </Paper>
                                    )}
                                  </Box>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          })}
                        </Timeline>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Box>
        </Container>
      );
    } else {
      if (isManager) {
        return (
          <Container maxWidth="md">
            <Box my={4}>
              <QuoteStatusHeader status={status} request={request} quote={quote} />
            </Box>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Box sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                  <SendQuoteHeader status={status} request={request} quote={quote} />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                {mode === "view" || mode === "sent" ? (
                  <Box>
                    <Grid container alignItems="center">
                      <Grid item xs={6}>
                        <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                          {quote.uid && (
                            <img
                              style={{ width: "24px", height: "24px", borderRadius: "12px" }}
                              src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${quote.uid}.webp`}
                            />
                          )}
                          <Typography variant="overline" color="textSecondary">
                            Quote
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} align="right">
                        <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                          {quote.status === "paid" && request.orderId && (
                            <IconButton color="secondary" component={RouterLink} to={`/order/${request.orderId}`}>
                              <LocalMallOutlinedIcon />
                            </IconButton>
                          )}
                          {request.src === "direct" && isV2SaaSPlan(user.plan) && quote.status !== "paid" && (
                            <ShareButton type="quote" link={`/quote/${reqId}`} />
                          )}
                          {(request.src === "direct" || isV2SaaSPlan(user.plan)) && quote.status !== "paid" && (
                            <IconButton color="secondary" onClick={() => setMode("edit")}>
                              <EditOutlinedIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <QuoteItemGeneric request={request} quote={quote} />
                  </Box>
                ) : (
                  <BakerQuoteEntry request={request} quote={quote} onSubmit={onSubmit} onCancel={() => setMode("view")} />
                )}
              </Grid>
            </Grid>
          </Container>
        );
      } else {
        return <StaticPage title="Access Denied" desc="Sorry, you're not authorized to view this page." icon={PanToolOutlinedIcon}></StaticPage>;
      }
    }
  }
}
