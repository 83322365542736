import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Chip,
  Divider,
  LinearProgress,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert
} from "@mui/material";
import bkstApi from "../../api";
import { ExpandMoreOutlined } from "@mui/icons-material";
import StoreClosure from "../components/StoreClosure";

export default function MyStoreLocations(props) {
  const [storeLocations, setStoreLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/my/store-location`).then((res) => {
      setStoreLocations(res.data.storeLocations);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box>
          <Box my={6}>
            <Typography variant="h1" gutterBottom>
              Stores
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Configure store pickup & delivery options shown to customers during online checkout on your website.
            </Typography>
            <Box my={2}>
              <Alert severity="info">For any changes, please contact BakeStreet using the Online Chat option</Alert>
            </Box>
          </Box>
          <Box>
            {storeLocations.map((o) => {
              return (
                <Box my={4} key={o.id}>
                  <Paper elevation={0}>
                    <Box p={2}>
                      <Box pb={1}>
                        <Typography variant="body1">
                          {o.address.street}
                          {o.default == "true" && <Chip size="small" label="default"></Chip>}
                        </Typography>
                        <Typography variant="body1">
                          {o.address.city}, {o.address.state} {o.address.zip}
                        </Typography>
                      </Box>
                      <Box py={1.5}>
                        <Divider />
                      </Box>
                      <Box>
                        <Accordion elevation={0}>
                          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                            <Typography variant="overline">Pickup Hours</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box mb={2}>
                              <Typography variant="body2" color="textSecondary">
                                Customers will only be able to choose from the following pickup hours during online checkout
                              </Typography>
                            </Box>
                            <Box>
                              {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((d) => {
                                return (
                                  <Box my={1}>
                                    <Typography variant="overline" display="block" sx={{ fontWeight: "600" }}>
                                      {d.toUpperCase()}
                                    </Typography>
                                    {o.fulfillment.pickup.timeslots[d]?.length === 0 && (
                                      <Typography variant="caption" display="block" color="error">
                                        Closed
                                      </Typography>
                                    )}
                                    {o.fulfillment.pickup.timeslots[d].map((t) => {
                                      return <Chip size="small" label={t} />;
                                    })}
                                  </Box>
                                );
                              })}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      <Box py={1.5}>
                        <Divider />
                      </Box>
                      <Box>
                        <Accordion elevation={0}>
                          <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                            <Typography variant="overline">Delivery Hours</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box mb={2}>
                              <Typography variant="body2" color="textSecondary">
                                Customers will only be able to choose from the following delivery hours during online checkout
                              </Typography>
                            </Box>
                            <Box>
                              {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((d) => {
                                return (
                                  <Box my={1}>
                                    <Typography variant="overline" display="block" sx={{ fontWeight: "600" }}>
                                      {d.toUpperCase()}
                                    </Typography>
                                    {o.fulfillment.delivery.timeslots[d]?.length === 0 && (
                                      <Typography variant="caption" display="block" color="error">
                                        Closed
                                      </Typography>
                                    )}
                                    {o.fulfillment.delivery.timeslots[d].map((t) => {
                                      return <Chip size="small" label={t} />;
                                    })}
                                  </Box>
                                );
                              })}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      <Box py={1.5}>
                        <Divider />
                      </Box>
                      <StoreClosure storeLocation={o} />
                    </Box>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Container>
    );
  }
}
