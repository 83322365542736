import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { PRODUCT_TYPES_V2 } from "../constants";

export default function ProductTypeAutocomplete(props) {
  const { value } = props;
  const [options, setOptions] = useState(PRODUCT_TYPES_V2.map((o) => o.name));

  const onChange = (e, v) => {
    props.onChange(v || "");
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onChange}
      disableClearable={true}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      freeSolo
      autoSelect
      blurOnSelect={true}
      disable={true}
    />
  );
}
