import React from "react";
import { Box, Container, Typography } from "@mui/material";
import BakerEditFlavors from "./BakerEditFlavors";

export default function MyVariants(props) {
  return (
    <Container maxWidth="md">
      <Box my={6}>
        <Box mb={4}>
          <Typography variant="h1" gutterBottom>Variants</Typography>
          <Typography variant="body1" color="textSecondary">
            Customize options for your custom cake items
          </Typography>
        </Box>
        <BakerEditFlavors />
      </Box>
    </Container>
  );
}
