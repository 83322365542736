import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, IconButton, Link, FormHelperText } from "@mui/material";
import moment from "moment";
import bkstApi from "../../api";
import BDatePicker from "../../common/input/BDatePicker";
import CloseIcon from "@mui/icons-material/Close";

export default function BalancePaymentDate(props) {
  const { order } = props;

  // This is currently saved date
  const [dueDate, setDueDate] = useState(order.payment.dueDate || moment(order.fulfillment.date, "YYYY-MM-DD").subtract(7, "days").format());

  // This is the current date in the date picker
  const [date, setDate] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDate(dueDate);
  }, [open, dueDate]);

  const changeDueDate = () => {
    bkstApi
      .put(`/my/order/${order.id}/dueDate`, { date })
      .then((res) => {
        setDueDate(date);
        setOpen(false);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Link underline="always" onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
        {moment(dueDate).format("MMM D")}
      </Link>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="xs">
        <DialogTitle>
          Change Balance Due Date
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box pt={4}>
            <FormControl variant="outlined" fullWidth>
              <BDatePicker
                label="Balance Due Date"
                value={date}
                onChange={(d) => setDate(d)}
                disablePast
                maxDate={moment(order.fulfillment.date, "YYYY-MM-DD").subtract(1, "days").toDate()}
                required
                autoOk
              />
              {order.paymentType !== "offline" && (
                <FormHelperText>
                  This is the date when the balance payment for this order will be automatically charged to the card on file
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={changeDueDate} fullWidth disabled={!date}>
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
