import React from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { Box} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export default function NotificationIcon(props) {
  const { type, status } = props;

  let icon = <AssignmentIcon />;
  let color = "#999";
  let backgroundColor = "#ECECEC";

  if (type === "Quote") {
    icon = <VisibilityOutlinedIcon />;
  } else if (type.includes("New Order") || type.includes("Manual Order") || type.includes("Order for")) {
    icon = <LocalMallOutlinedIcon />;
  } else if (type.includes("message")) {
    icon = <ChatOutlinedIcon />;
  } else if (type.includes("Text")) {
    icon = <SmsOutlinedIcon />;
  } else if (type.includes("New Request")) {
    icon = <NewReleasesOutlinedIcon />;
  } else if (type.includes("Invoice")) {
    icon = <ReceiptOutlinedIcon />;
  } else if (type.includes("Payment")) {
    icon = <LocalAtmOutlinedIcon />;
  } else if (type === "Reopen") {
    icon = <LockOpenIcon />;
  } else if (type === "Review") {
    icon = <StarHalfOutlinedIcon />;
  } else if (type === "Feedback") {
    icon = <FeedbackIcon />;
  } else if (type.includes("Order Updated")) {
    icon = <EditOutlinedIcon />;
  } else if (type.includes("Preponed")) {
    icon = <HistoryOutlinedIcon />;
  } else if (type.includes("Cash Advance")) {
    icon = <AttachMoneyIcon />;
    backgroundColor = "#16a085";
    color = "#FFF";
  }

  if (status === "closed") {
    backgroundColor = "#DDD";
    color = "#999";
  }

  return <Box style={{ padding: "8px", backgroundColor, color }}>{icon}</Box>;
}
