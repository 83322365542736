import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Chip } from "@mui/material";
import { capitalize } from "../util";

export default function FilterChip(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("");

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  useEffect(() => {
    handleClose();
    props.onChange(value);
  }, [value]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Chip
        label={
          value ? (
            <Box>
              {props.label} <span style={{ color: "#999" }}>|</span> <span style={{ color: "#78374a", textTransform: "capitalize" }}>{value}</span>
            </Box>
          ) : (
            props.label
          )
        }
        onClick={handleClick}
        onDelete={
          value
            ? () => {
                setValue("");
              }
            : null
        }
      />
      <Menu
        id={`${props.label}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "240px",
            paddingBottom: "15px"
          }
        }}
      >
        {props.values.map((o, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              setValue(o);
            }}
            sx={{ textTransform: "capitalize" }}
          >
            {o}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
