import React, { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Container, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../api";
import { getQueryParam } from "../../common/util";
import { ExpandMoreOutlined } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SAAS_FEATURES = {
  CUSTOM_WEBSITE: {
    title: "Custom Website",
    desc: "Showcase your brand on your custom website. Delight customers with your conversational lead-gen form that also saves you tons of back & forth."
  },
  WORRY_FREE_PAYMENTS: {
    title: "Worry-free Payments",
    desc: "Stop chasing customers for balance payments. BakeStreet automatically collects balance payment 7 days before the order due date."
  },
  AUTO_SALES_ENGAGEMENT: {
    title: "Sales Automation",
    desc: "A little nudge, a timely follow-up goes a long way in engaging customers & generating more sales."
  },
  POLICY: {
    title: "Cancelation & Refund Policy",
    desc: "Protect yourself from disputes & chargebacks by showing a dynamic & comprehensive policy during checkout."
  },
  ONLINE_SHOP: {
    title: "Online Shop",
    desc: "Showcase items from your everyday & seasonal menu. Make it easy for customers to order from you."
  },
  CRM: {
    title: "Customer Relationship Management (CRM)",
    desc: "Improve customer interaction with powerful insights like loyalty level, orders & quote requests at your finger tips."
  },
  REMARKETING: {
    title: "Remarketing",
    desc: "Drive repeat business with timely, personalized & automated email and text campaigns."
  },
  //MANUAL_ORDERS: { title: "Manual Orders", desc: "Keep track of all your orders in one place." },
  AI_QUOTE: {
    title: "AI-powered Quotes",
    desc: "A revolutionary technology that generates accurate quotes for over 90% of your quote requests using your pricing model. Works like magic & saves hours everyday!"
  },
  BAKING_PLAN: {
    title: "Baking Plan",
    desc: "Save hours by generating your weekly baking plan in just one click"
  },
  DELIVERY_PLAN: {
    title: "Delivery Plan",
    desc: "Generate your delivery plan with just one click"
  },

  IVR: {
    title: "IVR Phone",
    desc: "Never miss a call, ever! Help customers with automated self-service 24x7 & dramatically improve customer experience."
  },
  MANAGER_ROLE: { title: "Manager Login", desc: "Limit access to your financials like earnings, tips, loan, etc." },
  STAFF_ROLE: { title: "Team Login", desc: "Multiple accounts with clearly-defined roles to manage your entire team" },

  TIPS: { title: "Preset Tips", desc: "Make 5-7% extra by making it easier for customers to leave a tip." },
  LOAN: { title: "Working Capital Loan", desc: "Borrow upto $100,000 on easy terms with automated repayment." },
  MULTI_LOCATION: { title: "Multiple Locations", desc: "Support for multiple store locations." },
  ORDER_FULFILLMENT: {
    title: "Kitchen Operations",
    desc: "Instant access to all confirmed orders for your kitchen staff. Add notes & instructions. Track order progress & more!"
  },

  EXPRESS_QUOTES: {
    title: "Express Quotes",
    desc: "Early bird catches the worm. Send professional quotes faster (under a minute) with our highly-optimized form."
  },
  VIRTUAL_ASSISTANT: {
    title: "Virtual Assistant",
    desc: "Our 24x7 online chat agents can answer most customer questions on your behalf. Think of it as an extension of your team that can save you a ton of time."
  },
  TRANSACTION_FEE: {
    title: "Flat Transaction Fee",
    desc: "Pay a flat 3.5% fee across multiple payment options like apple pay, card payment & automated balance payments."
  },
  ONE_PCT_SUCCESS_FEE: {
    title: "1% Success Fee",
    desc: "Success fee keeps us aligned as partners."
  },
  TRANSFER_FEES: {
    title: "Transfer your fees",
    desc: "Automatically pass on all fees to your customers & get the quoted amount in your bank account."
  }
};

const PLAN = {
  starter: {
    title: "Starter Plan",
    desc: "For bakeries where the owner manages all operations",
    pricingTable: process.env.REACT_APP_STARTER_PLAN_ID,
    features: [
      SAAS_FEATURES.CUSTOM_WEBSITE,
      SAAS_FEATURES.EXPRESS_QUOTES,
      SAAS_FEATURES.WORRY_FREE_PAYMENTS,
      SAAS_FEATURES.AUTO_SALES_ENGAGEMENT,
      SAAS_FEATURES.POLICY,
      SAAS_FEATURES.TRANSACTION_FEE,
      SAAS_FEATURES.ONE_PCT_SUCCESS_FEE,
      SAAS_FEATURES.TRANSFER_FEES
    ]
  },
  professional: {
    title: "Professional Plan",
    desc: "For bakeries with a store manager who handles some (or all) operations",
    pricingTable: process.env.REACT_APP_PROFESSIONAL_PLAN_ID,
    features: [
      SAAS_FEATURES.MANAGER_ROLE,
      SAAS_FEATURES.REMARKETING,
      SAAS_FEATURES.CRM,
      SAAS_FEATURES.LOAN,
      SAAS_FEATURES.VIRTUAL_ASSISTANT,
      //SAAS_FEATURES.MANUAL_ORDERS,
      SAAS_FEATURES.ONLINE_SHOP,
      SAAS_FEATURES.TIPS
    ]
  },
  premium: {
    title: "Premium Plan",
    desc: "For bakeries interested in streamlining all operations with technology",
    pricingTable: process.env.REACT_APP_PREMIUM_PLAN_ID,
    features: [
      SAAS_FEATURES.AI_QUOTE,
      SAAS_FEATURES.MULTI_LOCATION,
      SAAS_FEATURES.STAFF_ROLE,
      SAAS_FEATURES.ORDER_FULFILLMENT,
      SAAS_FEATURES.BAKING_PLAN,
      SAAS_FEATURES.DELIVERY_PLAN,
      SAAS_FEATURES.IVR
    ]
  }
};

export default function MyPlan(props) {
  const [plan, setPlan] = useState("");
  const [recommenedPlan, setRecommenedPlan] = useState("");
  const [loading, setLoading] = useState(true);

  const view = getQueryParam(window.location.search, "view") || "my";

  useEffect(() => {
    bkstApi(`/my/plan`).then((res) => {
      setPlan(res.data.plan);
      setRecommenedPlan(res.data.recommended);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else if (plan && view === "my") {
    const o = PLAN[plan];
    let features = o.features;
    if (plan === "premium") {
      features.push(...PLAN["professional"].features);
      features.push(...PLAN["starter"].features);
    } else if (plan === "professional") {
      features.push(...PLAN["starter"].features);
    }

    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Typography variant="h1" gutterBottom>Plan</Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            You are currently subscribed to the following plan
          </Typography>
        </Box>
        <Paper>
          <Box py={3} px={2} style={{ backgroundColor: "#ECECEC" }}>
            <Typography variant="h4">{o.title}</Typography>
          </Box>
          <Box p={2}>
            <Typography variant="overline" color="textSecondary">
              YOUR PLAN INCLUDES
            </Typography>
            {features.map((val, idx) => {
              return (
                <Box my={2}>
                  <Typography variant="body1" gutterBottom>
                    {val.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {val.desc}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Paper>
      </Container>
    );
  } else if (recommenedPlan) {
    const o = PLAN[recommenedPlan];

    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Box align="center" my={6}>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Upgrade your Plan
            </Typography>
          </Box>
          <Paper>
            <Box py={4} style={{ backgroundColor: "#ECECEC" }}>
              <stripe-pricing-table pricing-table-id={o.pricingTable} publishable-key={process.env.REACT_APP_STRIPE_KEY}></stripe-pricing-table>
            </Box>
            <Box p={2}>
              <Typography variant="overline" color="textSecondary">
                YOUR PLAN INCLUDES
              </Typography>

              {o.features.map((val) => {
                return (
                  <Box my={2}>
                    <Typography variant="body1" gutterBottom>
                      {val.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {val.desc}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container>
        <Box my={6}>
          <Box align="center" my={6}>
            <Typography variant="h1" gutterBottom>
              Pick your Plan
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Compare features to choose the right plan for your growing business
            </Typography>
          </Box>
          <Grid container>
            {Object.entries(PLAN).map(([k, o]) => {
              return (
                <Grid sm={4} xs={12}>
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    badgeContent={k === plan ? <CheckCircleIcon sx={{ color: "#16a085" }}/> : 0}
                  >
                    <Paper variant="outlined" style={{ marginLeft: "15px" }}>
                      <Box py={4} style={{ backgroundColor: "#ECECEC" }}>
                        <stripe-pricing-table
                          pricing-table-id={o.pricingTable}
                          publishable-key={process.env.REACT_APP_STRIPE_KEY}
                        ></stripe-pricing-table>
                      </Box>
                      <Accordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                          <Typography>See What's Included</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            {(k === "professional" || k === "premium") && (
                              <Box my={2}>
                                <Typography variant="body1" gutterBottom>
                                  ⭐ Everything in {k === "premium" ? "Professional" : "Starter"} plan, plus
                                </Typography>
                              </Box>
                            )}
                            {o.features.map((val) => {
                              return (
                                <Box my={2}>
                                  <Typography variant="body1" gutterBottom>
                                    {val.title}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {val.desc}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </Badge>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    );
  }
}
