import React, { useState } from "react";
import { Backdrop, Button, CircularProgress, Link, Typography } from "@mui/material";
import { upload } from "../../firebase";
import { placeholderImg } from "../../common/constants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

export default function PicUploadMultiple(props) {
  const [working, setWorking] = useState(false);

  async function handleUpload(event, folder, onComplete) {
    let tasks = [];
    setWorking(true);

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      tasks.push(
        new Promise(async (resolve, reject) => {
          upload(file, folder).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          });
        })
      );
    }

    const imageUrls = await Promise.all(tasks);
    console.log("ImageUrls", imageUrls);
    onComplete(imageUrls);
    setWorking(false);
  }

  const inputComponent = (
    <input
      accept="image/*"
      id={props.id}
      type="file"
      multiple
      onChange={(e) => handleUpload(e, props.folder, props.onComplete)}
      style={{ display: "none" }}
    />
  );

  if (props.showPlaceholder) {
    return (
      <Link component="label" style={{ textAlign: "center", cursor: "pointer" }} underline="hover">
        <Backdrop open={working} style={{ zIndex: "1000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <img src={placeholderImg} style={props.pstyle} />
        <Typography variant="body2">Add Photos</Typography>
        {inputComponent}
      </Link>
    );
  } else {
    return (
      <Button
        {...props}
        variant="contained"
        color="primary"
        disableElevation
        component="label"
        startIcon={working ? <CircularProgress size={18} /> : props.icon || <AddOutlinedIcon />}
      >
        {props.title}
        {inputComponent}
      </Button>
    );
  }
}
