import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { formatPhoneNumber, getFormattedAddressAsArray } from "../../common/util";
import currency from "currency.js";
import { bakeStreetLogo } from "../../common/constants";

export default function FulfillmentSummary(props) {
  const { orders, fulfillmentType } = props;
  return (
    <Box>
      <Box sx={{ marginBottom: "10pt" }}>
        <Box align="center">
          <img alt="logo" src={bakeStreetLogo} style={{ maxHeight: "16pt", marginBottom: "5pt" }}></img>
        </Box>
      </Box>
      <Box px={2} style={{ border: "1px solid #DDD" }}>
        <Grid container spacing={1} style={{ paddingTop: "20px", paddingBottom: "20px", borderBottom: "1px solid #EEE" }}>
          <Grid item xs={2}>
            <Typography variant="h6">Date & Time</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Address</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Customer</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Notes</Typography>
          </Grid>
        </Grid>
        {orders.map((o, idx) => {
          let address = getFormattedAddressAsArray(o.fulfillment.location, true);
          if (address.length === 0) {
            const addressArr = o.fulfillment.address.split(",");
            const addressLine1 = addressArr.shift();
            const addressLine2 = addressArr.join();

            address.push(addressLine1);
            address.push(addressLine2);
          }

          return (
            <Grid key={idx} container spacing={1} style={{ paddingTop: "20px", paddingBottom: "20px", borderBottom: "1px solid #EEE" }}>
              <Grid item xs={2}>
                <Typography variant="body2">{moment(o.fulfillment.date).format("ddd, MMM D")}</Typography>
                <Typography variant="overline" color="textSecondary">
                  {o.fulfillment.time}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {address.length > 0 && (
                  <Typography variant="body2" display="block">
                    {address[0]}
                  </Typography>
                )}
                {address.length > 1 && (
                  <Typography variant="body2" display="block">
                    {address[1]}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {o.customer.name} {o.customer.lastName || ""}
                </Typography>
                <Typography variant="overline" color="textSecondary">
                  {formatPhoneNumber(o.customer.phone)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    {o.fulfillment.type === "delivery" && (
                      <Typography variant="overline" color="textSecondary">
                        {o.fulfillment.provider ? o.fulfillment.provider.name : "In-House"} Delivery
                      </Typography>
                    )}
                    {o.fulfillment.instructions}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
}
