import React from "react";
import { Box, Button, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import moment from "moment";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import AssistedCheckout from "../../common/component/AssistedCheckout";

export default function QuoteStatusHeader(props) {
  const navigate = useNavigate();
  let { request, quote, status } = props;

  let Icon = InfoOutlinedIcon;
  let title = "";
  let description = "";
  let color = "#777";
  let backgroundColor = "#F7F7F7";

  if (status === "paid") {
    Icon = TaskAltIcon;
    title = "Order Placed";
    description = "Customer accepted this quote & placed an order";

    color = "#1e4620";
    backgroundColor = "#edf7ed";
  } else if (status === "unavailable") {
    Icon = DoNotDisturbAltOutlinedIcon;
    title = "Not Quoted";
    description = `You were marked as unavailable for this quote request`;

    color = "#5f2120";
    backgroundColor = "#fdeded";
  } else if (status === "pending") {
    Icon = WarningAmberOutlinedIcon;
    title = "Quote Pending";
    description = `This quote is pending since ${moment(quote?.reqTs?._seconds * 1000).fromNow()}`;

    color = "#663c00";
    backgroundColor = "#fff4e5";
  } else if (status === "active" || status === "review") {
    Icon = ScheduleSendOutlinedIcon;
    title = "Quote Sent";
    description = `This quote expires ${moment(quote.exdate).fromNow()}`;

    color = "#0288d1";
    backgroundColor = "#e5f6fd";
  } else if (status === "expired") {
    Icon = WatchLaterOutlinedIcon;
    title = "Quote Expired";
    description = `This quote expired ${moment(quote.exdate).fromNow()}`;

    color = "#5f2120";
    backgroundColor = "#fdeded";
  }

  const extendExpiration = () => {
    bkstApi.put(`/my/quote/${request.id}/exdate`, {}).then((res) => {
      bkstApi(`/my/quote/${request.id}`).then((res) => {
        navigate(0);
      });
    });
  };

  const daysToEvent = moment(request.date).diff(moment(), "days");

  let cta = "";
  if (status === "expired" && daysToEvent >= 0) {
    cta = (
      <Box mt={2} sx={{ textAlign: "center" }}>
        <Button color="primary" variant="contained" onClick={extendExpiration} fullWidth>
          Extend Expiration
        </Button>
        <Box>
          <Typography variant="caption" color="textSecondary">
            Extend quote expiration by the recommended duration based on the event date
          </Typography>
        </Box>
      </Box>
    );
  } else if (status === "active" && quote.resTs && +moment().diff(moment(quote.resTs._seconds * 1000), "seconds") < 60) {
    cta = (
      <Box mt={2} sx={{ textAlign: "center" }}>
        <Button color="primary" variant="outlined" component={RouterLink} to={`/quote`} fullWidth>
          Check For Pending Quotes
        </Button>
        <Box>
          <Typography variant="caption" color="textSecondary">
            Timely quotes improve customer satisfaction & generate more business
          </Typography>
        </Box>
      </Box>
    );
  } else if (request.src === "direct" && request.status !== "closed" && (quote.status === "active" || quote.status === "expired")) {
    cta = (
      <Box mt={2}>
        <Box textAlign={"center"}>
          <AssistedCheckout reqId={request.id} status={status} />
          <Typography variant="caption" color="textSecondary">
            Confirm order by taking payment for this quote. Ideal for customers in-store or over the phone
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box px={1} py={1.5} sx={{ color, backgroundColor, borderRadius: "4px", textAlign: "left" }}>
        <Typography style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Icon style={{ fontSize: "2.2em" }} />
          <Box>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Typography>
      </Box>
      {cta}
    </Box>
  );
}
