import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@mui/material";
import bkstApi from "../../api";
import moment from "moment";
import { ExpandMoreOutlined } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BDatePicker from "../../common/input/BDatePicker";

export default function StoreClosure(props) {
  const { storeLocation } = props;
  const [blockedDates, setBlockedDates] = useState(storeLocation.rules?.date?.block || []);

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");

  const handleSubmit = () => {
    bkstApi.put(`/my/store-location/${storeLocation.id}`, { action: "ADD", rules: { date: { block: date } } }).then((res) => {
      setBlockedDates([...blockedDates, date]);
      handleClose();
    });
  };

  const handleDelete = (date) => {
    if (
      window.confirm(`Are you sure you want to allow customers to pick ${moment(date, "YYYY-MM-DD").format("MMM D, YYYY")} during checkout`) == true
    ) {
      bkstApi.put(`/my/store-location/${storeLocation.id}`, { action: "DELETE", rules: { date: { block: date } } }).then((res) => {
        setBlockedDates(blockedDates.filter((o) => o !== date));
        handleClose();
      });
    } else {
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDate("");
  };

  return (
    <Box>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="overline">Store Closure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary">
              Customers will not be able to select the following dates during online checkout
            </Typography>
          </Box>
          <Box>
            {blockedDates
              .filter((o) => o >= moment().format("YYYY-MM-DD"))
              .sort()
              .map((d) => {
                return <Chip label={moment(d).format("MMM D, YYYY")} onDelete={() => handleDelete(d)} />;
              })}
            <Chip color="primary" icon={<AddOutlinedIcon />} clickable label="ADD" onClick={() => setOpen(true)} />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Pick a Date to Block</DialogTitle>
        <DialogContent>
          <Box pt={4}>
            <BDatePicker
              value={date}
              label={"Date"}
              onChange={(d) => setDate(d)}
              disablePast
              autoOk
              shouldDisableDate={(d) => blockedDates.includes(moment(d).format("YYYY-MM-DD"))}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary" variant="outlined" fullWidth>
            Cancel
          </Button>
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit} disabled={!date}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
