import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate, useLocation } from "react-router-dom";
import StripePaymentElement from "../../common/component/StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { calculatePaymentSummary, getDefaultQuoteExpiration } from "../../common/util";
import FeatureAccess from "../../baker/components/FeatureAccess";
import CustomerDetailEntry from "../../customer/component/CustomerDetailEntry";
import PaymentTotal from "../component/PaymentTotal";
import PaymentDue from "../component/PaymentDue";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import FulfillmentEntry from "../../common/component/fulfillment/FulfillmentEntry";
import moment from "moment";
import MotoOrder from "../../invoice/screen/MotoOrder";
import { UserContext } from "../../context/UserContext";
import LineItemsV2 from "../../common/component/lineitem/LineItemsV2";
import BDatePicker from "../../common/input/BDatePicker";
import PaymentStatus from "../../common/screen/PaymentStatus";
import currency from "currency.js";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function CreateOrder(props) {
  const navigate = useNavigate();
  let location = useLocation();

  const { user } = useContext(UserContext);

  const [items, setItems] = useState([]);

  const [customer, setCustomer] = useState("");

  const [fulfillment, setFulfillment] = useState({ type: "pickup" });

  const [chargeType, setChargeType] = useState("now");
  const [paymentMethod, setPaymentMethod] = useState(user.config?.readerId ? "reader" : "keyed");
  const [acceptDeposit, setAcceptDeposit] = useState(false);
  const [deposit, setDeposit] = useState("");
  const [dueDate, setDueDate] = useState("");

  const [mode, setMode] = useState("itemized");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state) {
      setItems(location.state.items);
      setCustomer(location.state.customer);
      setFulfillment({ ...fulfillment, date: location.state.fulfillment?.date });
    }
  }, []);

  const validate = () => {
    let arr = [];
    if (!fulfillment.date) {
      arr.push("Date is missing");
    }
    if (!fulfillment.location) {
      if (fulfillment.type === "pickup") {
        arr.push("Pickup Location is missing");
      } else if (fulfillment.type === "delivery") {
        arr.push("Delivery Address is missing");
      }
    }
    if (!customer.name) {
      arr.push("Customer Name is missing");
    }
    if (!customer.phone) {
      arr.push("Phone is missing");
    }
    if (acceptDeposit) {
      if (!deposit) {
        arr.push("Deposit amount is missing");
      }
      if (!dueDate) {
        arr.push("Balance due date is missing");
      }
    }

    return arr;
  };

  useEffect(() => {
    setDeposit("");

    const daysToDueDate = moment(fulfillment.date).diff(moment(), "days", true);
    console.log("daysToDueDate", daysToDueDate);
    let defaultDaysBefore = 0;
    if (daysToDueDate > 7) {
      defaultDaysBefore = 7;
    } else if (daysToDueDate > 3) {
      defaultDaysBefore = 2;
    } else {
      defaultDaysBefore = 1;
    }
    console.log("defaultDaysBefore", defaultDaysBefore);
    setDueDate(moment(fulfillment.date, "YYYY-MM-DD").subtract(defaultDaysBefore, "days").format("YYYY-MM-DD"));
  }, [acceptDeposit]);

  useEffect(() => {
    if (chargeType === "now") {
      setPaymentMethod(user.config?.readerId ? "reader" : "keyed");
      setAcceptDeposit(false);
    } else {
      setPaymentMethod("");
      setAcceptDeposit(false);
    }
  }, [chargeType]);

  useEffect(() => {
    if (fulfillment?.date) {
      setAcceptDeposit(false);
      setDeposit("");
      setDueDate("");
    }
  }, [fulfillment?.date]);

  const chargePaymentMethod = (paymentMethodId, paymentSummary) => {
    setLoading(true);

    const payload = {
      amount: paymentSummary.due,
      dueDate,
      items,
      fulfillment,
      customer,
      paymentMethodId
    };

    bkstApi
      .post(`/my/payment`, payload)
      .then((res) => {
        navigate(`/order/${res.data.orderId}`);
      })
      .catch((e) => {
        setErrors([`Payment Failed: ${e.response?.data?.message}`]);
        setLoading(false);
      });
  };

  const sendToCardReader = () => {
    const arr = validate();
    setErrors(arr);

    if (arr.length === 0) {
      const payload = {
        amount: paymentSummary.due,
        dueDate,
        items,
        fulfillment,
        customer
      };

      return bkstApi.post(`/my/reader/${user.config.readerId}`, payload);
    }
  };

  const createOfflineOrder = () => {
    const arr = validate();

    if (arr.length > 0) {
      setErrors(arr);
    } else {
      setLoading(true);

      const payload = {
        amount: paymentSummary.due,
        dueDate,
        items,
        fulfillment,
        customer
      };

      bkstApi
        .post(`/my/order`, payload)
        .then((res) => {
          navigate(`/order/${res.data.orderId}`);
        })
        .catch((e) => {
          setErrors([`Error: ${e.response?.data?.message}`]);
          setLoading(false);
        });
    }
  };

  const sendInvoice = () => {
    const arr = validate();

    if (arr.length > 0) {
      setErrors(arr);
    } else {
      setLoading(true);

      let f = { date: fulfillment.date, option: { pickup: {}, delivery: { type: "no" } } };
      if (fulfillment.type === "delivery") {
        if (user.config?.fulfillment?.delivery?.["third-party"] === "metrobi") {
          f.option.delivery.type = "metrobi";
        } else {
          f.option.delivery.type = "in-house";
          f.option.delivery.location = fulfillment.location;

          // default to free delivery
          f.option.delivery.price = 0;
          f.option.delivery.includeFees = user.config?.transferFees === "false" ? false : true;
          f.option.delivery.total = 0;
        }
      }

      const payload = {
        items,
        fulfillment: f,
        customer,
        exdate: getDefaultQuoteExpiration(fulfillment.date),
        mode: "send"
      };
      console.log("payload", payload);

      bkstApi
        .post(`/my/quote`, payload)
        .then((res) => {
          navigate(`/quote/${res.data.quoteId}`);
        })
        .catch((e) => {
          setErrors([`Error: ${e.response?.data?.message}`]);
          setLoading(false);
        });
    }
  };

  const paymentSummary = calculatePaymentSummary(items, fulfillment.deliveryFee, acceptDeposit, deposit);
  const disableDepositOption = !fulfillment.date || moment(fulfillment.date).startOf("day").diff(moment(), "days", true) < 1;

  return (
    <Container maxWidth="sm">
      <FeatureAccess plan={["starter", "professional", "premium"]} />
      <Box my={6}>
        <Box mb={2} align="center">
          <Typography variant="h2" gutterBottom>
            Create Order
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Charge payment for in-store and phone orders
          </Typography>
        </Box>
        <Tabs value={mode} centered>
          <Tab value="itemized" label="Itemized Sale" onClick={() => setMode("itemized")} />
          <Tab value="quick" label="Quick Charge" onClick={() => setMode("quick")} />
        </Tabs>
        {mode === "quick" && (
          <Box my={4}>
            <MotoOrder hideHeader={true} />
          </Box>
        )}
        {mode === "itemized" && (
          <Box my={4}>
            <LineItemsV2 items={items} onChange={(arr) => setItems(arr)} />
            {items.length > 0 && (
              <Box>
                <Box my={4}></Box>
                <Typography variant="overline" color="textSecondary">
                  CUSTOMER
                </Typography>
                <Paper elevation={0}>
                  <Box px={2} pt={3} pb={1}>
                    <CustomerDetailEntry customer={customer} onChange={(o) => setCustomer(o)} />
                  </Box>
                </Paper>
                <Box my={4}></Box>
                <Typography variant="overline" color="textSecondary">
                  SHIPPING
                </Typography>
                <Paper elevation={0}>
                  <Box px={2} pt={1} pb={2}>
                    <FulfillmentEntry fulfillment={fulfillment} onChange={(o) => setFulfillment(o)} />
                  </Box>
                </Paper>
                <Box my={4}></Box>
                <Typography variant="overline" color="textSecondary">
                  PAYMENT SUMMARY
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    <PaymentTotal payment={paymentSummary} />
                    <Box pt={1} mt={2} sx={{ borderTop: "1px solid #EEE" }}>
                      <PaymentDue total={paymentSummary.total} due={paymentSummary.due} date={fulfillment.date} />
                    </Box>
                  </Box>
                </Paper>

                <Box my={4}></Box>
                <Tabs value={chargeType} variant="scrollable" scrollButtons="auto">
                  <Tab value="now" label="Charge Now" onClick={() => setChargeType("now")} />
                  <Tab value="later" label="Charge Later" onClick={() => setChargeType("later")} />
                </Tabs>
                {chargeType === "later" && (
                  <Box>
                    <Paper elevation={0}>
                      <Box px={2} py={3}>
                        <Typography variant="h6" gutterBottom>
                          How it works?
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          Customer will be notified via text & email. The order will be confirmed when the customer pays for the order.
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                )}
                {chargeType === "now" && (
                  <Box>
                    <Paper elevation={0}>
                      <Box p={2} pt={4}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="paymentMethod-label">Payment Method</InputLabel>
                              <Select
                                value={paymentMethod}
                                labelId="paymentMethod-label"
                                label="Payment Method"
                                id="paymentMethod"
                                name="paymentMethod"
                                onChange={(e) => setPaymentMethod(e.target.value)}
                              >
                                {user.config.readerId && <MenuItem value="reader">Card Reader</MenuItem>}
                                <MenuItem value="keyed">Keyed (manually enter card)</MenuItem>
                                <MenuItem value="cash" disabled={!(user.plan === "professional" || user.plan === "premium")}>
                                  Cash
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="acceptDeposit-label">Payment Type</InputLabel>
                              <Select
                                value={acceptDeposit}
                                labelId="acceptDeposit-label"
                                label="Payment Type"
                                id="acceptDeposit"
                                name="acceptDeposit"
                                onChange={(e) => setAcceptDeposit(e.target.value)}
                              >
                                <MenuItem value={false}>Charge in Full</MenuItem>
                                <MenuItem value={true} disabled={disableDepositOption}>
                                  Charge a Deposit {disableDepositOption && `(unavailable in last 2 days)`}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {acceptDeposit && (
                            <Grid item xs={6}>
                              <TextField
                                id="deposit"
                                type="number"
                                value={deposit}
                                label="Deposit Amount"
                                name="deposit"
                                variant="outlined"
                                fullWidth
                                required
                                onChange={(e) => setDeposit(e.target.value)}
                                onWheel={(e) => {
                                  e.target && e.target.blur();
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <AttachMoneyOutlinedIcon />
                                    </InputAdornment>
                                  )
                                }}
                                error={+deposit >= +paymentSummary.total}
                                helperText={+deposit >= +paymentSummary.total ? "Deposit amount should be less than total amount" : ""}
                              />
                            </Grid>
                          )}
                          {acceptDeposit && (
                            <Grid item xs={6}>
                              <FormControl variant="outlined" fullWidth>
                                <BDatePicker
                                  label="Balance Due Date"
                                  value={dueDate}
                                  onChange={(d) => setDueDate(d)}
                                  disablePast
                                  maxDate={moment(fulfillment.date, "YYYY-MM-DD").subtract(1, "days").toDate()}
                                  required
                                  autoOk
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                        {/*acceptDeposit && moment(fulfillment.date, "YYYY-MM-DD").diff(moment(dueDate, "YYYY-MM-DD"), "days") < 3 && (
                          <Box mt={2}>
                            <Alert severity="warning">
                              By creating an order with a deposit less than 3 days from the event date, you're taking on the responsibility to collect
                              the balance payment
                            </Alert>
                          </Box>
                        )*/}
                      </Box>
                    </Paper>
                  </Box>
                )}
                {errors && errors.length > 0 && (
                  <Box my={2}>
                    <Alert severity="error">{errors.join(". ")}</Alert>
                  </Box>
                )}
                <Box my={2}>
                  {paymentMethod === "keyed" && (
                    <Box>
                      <Typography color="textSecondary" variant="body2" gutterBottom>
                        Enter the customer's card details below to confirm this order
                      </Typography>
                      <Elements stripe={promise}>
                        <StripePaymentElement
                          disabled={loading}
                          validate={validate}
                          chargePaymentMethod={(paymentMethodId) => chargePaymentMethod(paymentMethodId, paymentSummary)}
                          amount={paymentSummary.due}
                          name={customer.name}
                          phone={customer.phone}
                        />
                      </Elements>
                    </Box>
                  )}
                  {paymentMethod === "cash" && (
                    <Button color="primary" variant="contained" onClick={createOfflineOrder} fullWidth>
                      CREATE CASH ORDER
                    </Button>
                  )}
                  {chargeType === "later" && (
                    <Button color="primary" variant="contained" onClick={sendInvoice} fullWidth>
                      SEND INVOICE
                    </Button>
                  )}
                  {paymentMethod === "reader" && (
                    <Box my={4}>
                      <PaymentStatus amount={paymentSummary.due} onSubmit={sendToCardReader} readerId={user.config.readerId} />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Backdrop open={loading} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
