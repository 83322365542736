import React, { useState, useContext } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import IconText from "../IconText";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { getSaasWebsiteUrl } from "../util";

export default function AssistedCheckout(props) {
  const { user } = useContext(UserContext);
  const { reqId, status } = props;

  const navigate = useNavigate();

  const [showIntro, setShowIntro] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    navigate(0);
    setOpen(false);
  };

  const saasDomainUrl = getSaasWebsiteUrl(user.domain);

  return (
    <span>
      <Button color="primary" variant="contained" fullWidth onClick={() => setOpen(true)}>
        Take Payment
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          Confirm Order using Virtual Terminal
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0", margin: "0" }}>
          {showIntro && (
            <Box p={2}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Virtual Terminal turns your device into a credit card terminal. Easily collect payment over phone or in-person to create an order
                using this quote.
              </Typography>
              <Box my={2}>
                <Typography variant="h6" gutterBottom>
                  How it works?
                </Typography>
                <Box sx={{ color: "#555" }}>
                  <IconText icon={CheckOutlinedIcon} text={`Customize order details (e.g. Flavor)`} variant="body2" />
                  <IconText icon={CheckOutlinedIcon} text={`Proceed to Checkout & choose Pickup/Delivery options`} variant="body2" />
                  <IconText icon={CheckOutlinedIcon} text={`Enter customer's card details to confirm the order`} variant="body2" />
                </Box>
              </Box>
              {status === "expired" && (
                <Alert severity="error">
                  This quote has expired. To place an order, you first need to edit the quote & extend the quote expiration.
                </Alert>
              )}
            </Box>
          )}
          {open && !showIntro && (
            <Box>
              <iframe src={`${saasDomainUrl}/quote/${reqId}?su=${user.id}`} style={{ width: "100%", height: "100vh", border: "0" }} />
            </Box>
          )}
        </DialogContent>
        {showIntro && (
          <DialogActions>
            <Button color="primary" variant="contained" onClick={() => setShowIntro(false)} disabled={status === "expired"}>
              Continue
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </span>
  );
}
