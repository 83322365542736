import React, { useContext, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { FROSTING } from "../constants";
import { UserContext } from "../../context/UserContext";

export default function FrostingAutocomplete(props) {
  const { user } = useContext(UserContext);

  const { productType, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake" || productType === "Cupcakes") {
      setOptions(user.config?.frosting || FROSTING);
    } else if (productType === "Cookies") {
      setOptions(["Fondant", "Royal Icing", "Sugar"]);
    } else if (productType === "Cake Pops") {
      setOptions(["Candy Melts", "Chocolate"]);
    }
  }, [productType]);

  const onChange = (e, v) => {
    props.onChange(v || "");
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onChange}
      disableClearable={true}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      freeSolo
      blurOnSelect={true}
      autoSelect
    />
  );
}
