import React from "react";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import OrderProgress from "../../order/component/OrderProgress";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import HTMLViewer from "../../common/component/HTMLViewer";
import LineItemDetails from "../../common/component/lineitem/LineItemDetails";
import ImageCarousel from "../../common/component/ImageCarousel";

export default function KitchenOrder(props) {
  const { order } = props;

  let alert = <Box></Box>;
  if (order.status === "fulfilled" || order.status === "completed") {
    alert = (
      <Box>
        <Alert severity="success">This order has been completed</Alert>
      </Box>
    );
  } else if (order.status === "canceled") {
    alert = (
      <Box>
        <Alert severity="error">This order has been canceled</Alert>
      </Box>
    );
  }

  return (
    <Box my={6}>
      <Grid container alignItems={"center"}>
        <Grid item xs={11}>
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center", flexWrap: "wrap" }}>
            <CustomerContactInfoButton customer={order.customer} />
            <Typography variant="overline" style={{ display: "flex", alignItems: "center", fontSize: ".8rem", color: "#777", gap: "4px" }}>
              <span style={{ color: "#CCC" }}>{` | `}</span>
              {moment(order.fulfillment.date).format("ddd, MMM D")} [{order.fulfillment.time}]
            </Typography>
            <Typography variant="overline" style={{ display: "flex", alignItems: "center", fontSize: ".8rem", color: "#777", gap: "4px" }}>
              <span style={{ color: "#CCC" }}>{` ● `}</span>
              {order.fulfillment.type}
            </Typography>
            {order.fulfillment.type === "pickup" && (
              <Typography variant="overline" style={{ display: "flex", alignItems: "center", fontSize: ".8rem", color: "#777", gap: "4px" }}>
                <span style={{ color: "#CCC" }}>{` ● `}</span>
                {order.fulfillment.location ? order.fulfillment.location.street : order.fulfillment.address.split(",")[0]}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={1} align="right">
          <OrderProgress orderId={order.id} progress={order.progress} />
        </Grid>
      </Grid>
      <Paper elevation={1}>
        {alert}
        <Box px={2} py={3}>
          <Box>
            {order.items.map((item, idx) => (
              <Box key={idx} my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={1} textAlign={"center"}>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        border: "1px solid #DDD",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="h6">{item.quantity} </Typography>
                    </Box>
                    <Typography variant="overline">QTY</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <ImageCarousel images={item.images} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom>
                      {item.title}
                    </Typography>
                    <LineItemDetails item={item} />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
          {order.invoice && (
            <Box my={2}>
              {Object.entries(order.invoice)
                .filter(([k, v]) => v.status !== "void")
                .map(([key, value]) => {
                  return (
                    <Grid item xs={6}>
                      <Typography variant="overline" color="textSecondary">
                        ADD-ON
                      </Typography>
                      <Typography variant="body2">{value.description}</Typography>
                    </Grid>
                  );
                })}
            </Box>
          )}
          {order.kitchen && (
            <Box my={2}>
              <Typography variant="overline" color="textSecondary">
                INSTRUCTIONS
              </Typography>
              <HTMLViewer content={order.kitchen.notes} style={{ fontSize: ".85rem" }} />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
