import React, { useContext, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { SHAPE } from "../constants";
import { UserContext } from "../../context/UserContext";

export default function ProductShapeAutocomplete(props) {
  const { user } = useContext(UserContext);

  const { productType, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake") {
      if (user.config?.sizeChart) {
        setOptions((Object.keys(user.config.sizeChart?.[productType]) || []).sort());
      } else {
        setOptions(SHAPE);
      }
    } else {
      setOptions(["Custom"]);
    }
  }, [productType]);

  const onChange = (e, v, reason) => {
    props.onChange(v || "");
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onChange}
      disableClearable={true}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      freeSolo
      blurOnSelect={true}
      autoSelect
    />
  );
}
