import React, { useContext } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { formatPhoneNumber, getFormattedAddressAsArray } from "../../common/util";
import { BAKER_PIC_FOLDER, STORAGE_BUCKET } from "../../common/constants";
import { UserContext } from "../../context/UserContext";

export default function FulfillmentSlip(props) {
  const { user, setUser } = useContext(UserContext);

  const { order } = props;

  let address = getFormattedAddressAsArray(order.fulfillment.location, true);
  if (address.length === 0) {
    address.push(order.fulfillment.address);
  }

  return (
    <Box p={4} sx={{ border: "1px solid #ECECEC" }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="overline" color="textSecondary">
            Name
          </Typography>
          <Typography variant="h4" gutterBottom>
            {order.customer.name} {order.customer.lastName || ""}
          </Typography>
          <Typography variant="body1">{formatPhoneNumber(order.customer.phone)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="overline" color="textSecondary">
            Date & Time
          </Typography>
          <Typography variant="h4" gutterBottom>
            {moment(order.fulfillment.date).format("MMM DD")}
          </Typography>
          <Typography variant="body1">{order.fulfillment.time}</Typography>
        </Grid>
        <Grid item xs={2} textAlign={"right"}>
          <Box>
            <img style={{ width: "100%" }} src={`${BAKER_PIC_FOLDER}/${user.acctId}/logo400.webp`} />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="overline" color="textSecondary">
            {order.fulfillment.type} Address
          </Typography>
          <Typography variant="body1">{address.join(", ")}</Typography>
          {order.fulfillment.instructions && <Typography variant="body1">{`DELIVERY INSTRUCTIONS: ${order.fulfillment.instructions}`}</Typography>}
        </Grid>
        <Grid item xs={2} textAlign={"right"}>
          {order.fulfillment.provider && (
            <img
              style={{ width: "24px", height: "24px", borderRadius: "12px" }}
              src={`${STORAGE_BUCKET}/img/logo/${order.fulfillment.provider.name.toLowerCase()}.webp`}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Order Summary
          </Typography>
          {order.items &&
            order.items.map((item, idx) => {
              return (
                <Typography variant="body1" display="block">
                  {item.quantity || 1}x {item.title}
                </Typography>
              );
            })}
        </Grid>
      </Grid>
      <Box my={7}>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            By signing below, I acknowledge the receipt of my order. Furthermore, I declare that I inspected my order and found everything to be
            satisfactory.
          </Typography>
        </Box>
        <Box sx={{ border: "1px solid #CECECE", height: "5rem" }}></Box>
      </Box>
    </Box>
  );
}
