import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { UserContext } from "../../../context/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import Images from "../../../request/components/Images";
import { PRODUCT_TYPES_V2, QUOTE_TAGS, STORAGE_BUCKET } from "../../constants";
import { Autocomplete } from "@mui/material";
import ItemCustomizationEntry from "./ItemCustomizationEntry";
import QuillRTE from "../QuillRTE";
import currency from "currency.js";
import Price from "../Price";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { getAmountWithTxnFee } from "../../util";
import ItemVariantEntryV2 from "./ItemVariantEntryV2";
import TextWithReadMore from "../../TextWithReadMore";

export default function LineItemEntryPopupV3(props) {
  const { user } = useContext(UserContext);
  const ref = useRef();

  const { mode, immutablePrice, idx } = props;

  const [item, setItem] = useState(props.item || "");
  const [errors, setErrors] = useState([]);

  const [open, setOpen] = useState(false);

  const validate = () => {
    let arr = [];
    if (!item.type?.trim()) {
      arr.push("Item Type is required");
    }
    if (item.type === "Other" && !item.title?.trim()) {
      arr.push("Title is required");
    }
    if (!(+item.price > 0)) {
      arr.push("Item Price is required");
    }
    if (!(+item.quantity > 0)) {
      arr.push("Quantity is required");
    }

    return arr;
  };

  const handleSubmit = () => {
    const arr = validate();
    setErrors(arr);

    if (arr.length > 0) {
      //ref.current?.scrollIntoView();
      return;
    } else {
      props.onChange(item);
      handleClose();
    }
  };

  useEffect(() => {
    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [item.type]);

  const handleDelete = () => {
    props.onChange("", "delete");
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setItem("");
    setErrors([]);
  };

  const updateTotal = (price, quantity, includeFees) => {
    const subTotal = currency(price).multiply(quantity);
    const total = includeFees ? getAmountWithTxnFee(subTotal) : subTotal;
    setItem({ ...item, price, quantity, includeFees, total });
  };

  const handleItemTypeChange = (type) => {
    let title = "";
    if (type !== "Other") {
      title = type;
    }
    setItem({ images: item.images, title, type, price: "", quantity: 1, includeFees: item.includeFees });
  };

  return (
    <>
      {mode === "add" ? (
        <Button
          disableElevation
          startIcon={<AddIcon />}
          variant={"contained"}
          color={idx === 0 ? "primary" : "secondary"}
          onClick={() => {
            setItem({ images: [], title: "", price: "", quantity: 1, includeFees: user.config?.transferFees === "false" ? false : true });
            setOpen(true);
          }}
          fullWidth
        >
          CUSTOM ITEM
        </Button>
      ) : (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            setItem(props.item);
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      )}
      {open && (
        <Dialog fullScreen={true} open={open} fullWidth={true}>
          <DialogTitle
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0",
              backgroundColor: "#DEDEDE",
              borderBottom: "1px solid #DDD"
            }}
          >
            <IconButton onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">{mode === "add" ? "Add Custom Item" : "Edit Item"}</Typography>
            <Box>
              {/*item.type && (
                <IconButton onClick={handleSubmit} color="primary">
                  <DoneIcon />
                </IconButton>
              )*/}
            </Box>
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#F7F7F7" }}>
            <Grid container>
              <Grid item lg={2} md={1} sm={0} xs={0}></Grid>
              <Grid item lg={8} md={10} sm={12} xs={12}>
                <Box px={2} pt={6} pb={20} ref={ref}>
                  {item.type && (
                    <Box>
                      <Grid container spacing={4}>
                        <Grid item sm={5} xs={6}>
                          <Box sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                            <Images edit={true} pics={item.images} onChange={(images) => setItem({ ...item, images })} folder={`tmp`} />
                          </Box>
                        </Grid>
                        <Grid item sm={7} xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Box>
                                <Paper elevation={0}>
                                  <Box p={2} pt={4}>
                                    <Autocomplete
                                      value={item.type || ""}
                                      onChange={(e, type) => {
                                        handleItemTypeChange(type);
                                      }}
                                      options={PRODUCT_TYPES_V2.map((o) => o.name)}
                                      renderInput={(params) => <TextField required={true} variant="outlined" {...params} label={"Item Type"} />}
                                      disableClearable
                                      autoSelect
                                      blurOnSelect={true}
                                      disabled={immutablePrice}
                                    />
                                    {item.type === "Other" && (
                                      <Box mt={3}>
                                        <TextField
                                          id="title"
                                          value={item.title}
                                          label="Title"
                                          name="title"
                                          variant="outlined"
                                          fullWidth
                                          onChange={(e) => setItem({ ...item, title: e.target.value })}
                                          required
                                          helperText="What's the item?"
                                          disabled={immutablePrice}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </Paper>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Typography variant="overline" color="textSecondary">
                                  Variant
                                </Typography>
                                <Paper elevation={0}>
                                  <Box p={2} pt={4}>
                                    <ItemVariantEntryV2
                                      productType={item.type}
                                      variant={item.variant || ""}
                                      onChange={(variant) => setItem({ ...item, variant })}
                                    />
                                  </Box>
                                </Paper>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Typography variant="overline" color="textSecondary">
                                  Customization
                                </Typography>
                                <Paper elevation={0}>
                                  <Box p={2} pt={4}>
                                    {item.type === "Cake" && (
                                      <Box>
                                        <ItemCustomizationEntry
                                          value={item.customization || ""}
                                          type={item.type}
                                          onChange={(customization) => setItem({ ...item, customization })}
                                        />
                                        <Box my={3}>
                                          <Autocomplete
                                            value={item.tags || []}
                                            onChange={(e, tags) => setItem({ ...item, tags })}
                                            options={QUOTE_TAGS}
                                            filterSelectedOptions
                                            renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                                            multiple
                                            freeSolo
                                          />
                                        </Box>
                                      </Box>
                                    )}
                                    <QuillRTE value={item.description || ""} onChange={(description) => setItem({ ...item, description })} />
                                    <Box py={0.4}>
                                      <TextWithReadMore
                                        data={`These notes are shown to customer. ${
                                          item.type === "Cake"
                                            ? `Use it to describe decor (edible image, ruffles, sugar flowers..), toppers (edible 3D
                      fondant, 2D cardstock) & more. Propose alternative options when sending quotes.`
                                            : ""
                                        }`}
                                        limit={140}
                                      />
                                    </Box>
                                  </Box>
                                </Paper>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box>
                                <Typography variant="overline" color="textSecondary">
                                  Pricing
                                </Typography>
                                <Paper elevation={0}>
                                  <Box p={2} pt={4}>
                                    <TextField
                                      id="price"
                                      type="number"
                                      value={item.price}
                                      label={"Item Price"}
                                      name="price"
                                      variant="outlined"
                                      fullWidth
                                      required
                                      onChange={(e) => {
                                        updateTotal(e.target.value, item.quantity, item.includeFees);
                                      }}
                                      onWheel={(e) => {
                                        e.target && e.target.blur();
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <AttachMoneyOutlinedIcon />
                                          </InputAdornment>
                                        )
                                      }}
                                      disabled={immutablePrice}
                                    />
                                    <Box my={3}>
                                      <TextField
                                        id="quantity"
                                        type="number"
                                        value={item.quantity}
                                        label="Quantity"
                                        name="quantity"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        onChange={(e) => {
                                          updateTotal(item.price, e.target.value, item.includeFees);
                                        }}
                                        inputProps={{ maxLength: 3 }}
                                        disabled={immutablePrice}
                                      />
                                    </Box>
                                    <Box>
                                      <Price
                                        label={"Total"}
                                        disableAmount={true}
                                        disableFees={immutablePrice}
                                        value={{ amount: currency(item.price).multiply(item.quantity).toString(), includeFees: item.includeFees }}
                                        onChange={(o) => {
                                          updateTotal(item.price, item.quantity, o.includeFees);
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Paper>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              {errors.length > 0 && (
                                <Box mb={2} sx={{ display: "block" }}>
                                  <Alert severity="error">{errors.join(". ")}</Alert>
                                </Box>
                              )}
                              <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                                Submit
                              </Button>
                              {mode !== "add" && !immutablePrice && (
                                <Box mt={5}>
                                  <Button color="error" onClick={handleDelete} fullWidth>
                                    Delete This Item
                                  </Button>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {!item.type && (
                    <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "center" }}>
                      {PRODUCT_TYPES_V2.map((o, idx) => {
                        return (
                          <Link
                            key={idx}
                            underline="hover"
                            color="inherit"
                            onClick={(e) => handleItemTypeChange(o.name)}
                            style={{ cursor: "pointer" }}
                          >
                            <Paper sx={{ width: "125px", height: "110px", padding: "15px 10px 10px 10px" }}>
                              <Box textAlign="center">
                                <img src={`${STORAGE_BUCKET}${o.img}`} style={{ height: "50px", width: "50px" }} />
                                <Typography
                                  variant="overline"
                                  display="block"
                                  sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                >
                                  {o.name}
                                </Typography>
                              </Box>
                            </Paper>
                          </Link>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
