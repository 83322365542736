import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { normalizeFlavor } from "../../common/util";

export default function ItemsRollupView(props) {
  const { items } = props;

  let rollup = {};
  for (const item of items) {
    let key = { type: item.type?.trim(), title: item.title?.trim() };
    if (item.variant) {
      if (item.variant?.size) {
        key.size = item.variant?.size?.size?.trim();
      }
      if (item.variant?.flavor) {
        const flavorVariant = normalizeFlavor(item.variant.flavor);
        key.flavor = flavorVariant.name?.trim();
      }
    }
    const keyStr = JSON.stringify(key).toLowerCase();

    let o = rollup[keyStr] || { items: [] };
    o.items.push(item);
    rollup[keyStr] = o;
  }

  // convert to arr
  let arr = [];
  for (const [k, v] of Object.entries(rollup)) {
    const key = JSON.parse(k);
    arr.push({ key, items: v.items });
  }
  // sort
  arr.sort((o1, o2) => {
    let compare = o1.key?.type?.localeCompare(o2.key?.type);
    if (compare === 0) {
      compare = o1.key?.title?.localeCompare(o2.key?.title);
    }
    return compare;
  });

  return (
    <Box>
      {arr.map((o) => {
        return (
          <Box my={2}>
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box
                    sx={{
                      minWidth: "40px",
                      width: "40px",
                      height: "40px",
                      border: "1px solid #DDD",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="h6">{o.items.reduce((acc, item) => +acc + (+item.quantity || 1), 0)} </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
                      {o.key.type} {o.key.title !== o.key.type ? `• ${o.key.title}` : ""}
                    </Typography>
                    <Typography variant="overline">
                      {o.key.size} • {o.key.flavor}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  {o.items.map((i, idx) => {
                    return (
                      <Box p={1} my={1} key={idx} sx={{ backgroundColor: "#F7F7F7" }}>
                        <LineItemV2 item={i} view="min" />
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
    </Box>
  );
}
