import React, { useContext, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { SERVINGS } from "../constants";
import { UserContext } from "../../context/UserContext";

export default function ProductServingsAutocomplete(props) {
  const { user } = useContext(UserContext);

  const { productType, shape, size, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake") {
      let arr = SERVINGS;
      if (user.config?.sizeChart) {
        arr = (user.config.sizeChart[productType]?.[shape] || []).map((o) => o.servings);
      }
      setOptions(arr);
    } else if (productType === "Cupcakes" || productType === "Cake Pops" || productType === "Cookies" || productType === "Shooters") {
      setOptions(["1 dozen", "2 dozen", "3 dozen", "4 dozen", "5 dozen"]);
    } else {
      setOptions([]);
    }
  }, [productType, shape, size]);

  const onChange = (e, v) => {
    props.onChange(v || "");
  };

  return (
    <Autocomplete
      value={value || ""}
      onChange={onChange}
      onInputChange={onChange}
      disableClearable={true}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      freeSolo
      blurOnSelect={true}
      autoSelect
    />
  );
}
