import React, { useEffect, useState } from "react";
import { bakeStreetSquareLogo } from "./constants";
import { Avatar, Box, Chip, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Typography } from "@mui/material";
import { isAdmin } from "./util";
import bkstApi from "../api";
import CloseIcon from "@mui/icons-material/Close";

export default function UserAvatar(props) {
  const { uid, by } = props;

  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);

  let style = props.style || { width: "32px", height: "32px", marginRight: "4px" };

  useEffect(() => {
    if (open && !user) {
      if (isAdmin(uid)) {
        setUser({ firstName: "BakeStreet", role: "admin" });
      } else if (uid) {
        bkstApi(`/my/staff/${uid}`).then((res) => {
          setUser(res.data.user);
        });
      }
    }
  }, [open]);

  const getUser = () => {
    if (isAdmin(uid)) {
      setUser({ firstName: "BakeStreet", role: "admin" });
      setOpen(true);
    } else if (uid) {
      bkstApi(`/my/staff/${uid}`).then((res) => {
        setUser(res.data.user);
        setOpen(true);
      });
    }
  };

  let img = "";
  if (by === "bkst" || isAdmin(uid)) {
    img = bakeStreetSquareLogo;
  } else if (uid) {
    img = `https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${props.uid}.webp`;
  }

  return (
    <span>
      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
        <Link onClick={getUser}>
          <Avatar src={img} style={style} />
        </Link>
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          User Details
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box pt={2}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <img src={img} style={{ width: "100%", border: "1px solid #DDD" }} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">
                  {user.firstName} {user.lastName}
                </Typography>
                <Chip label={user.role} />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
}
