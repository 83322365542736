import React, { useState, useEffect, useContext } from "react";
import { Box, Link, Typography } from "@mui/material";
import moment from "moment";
import currency from "currency.js";
import { UserContext } from "../../context/UserContext";
import { hasOwnerAccess } from "../../common/util";

export default function SummaryByDate(props) {
  const { user } = useContext(UserContext);

  const { from, to, date } = props;
  const [dateMap, setDateMap] = useState("");

  useEffect(() => {
    let tmp = {};
    for (const o of props.data) {
      let arr = tmp[o.date] || [];
      arr.push(o);
      tmp[o.date] = arr;
    }
    let summary = {};
    for (const [k, v] of Object.entries(tmp)) {
      summary[k] = { count: v.reduce((acc, item) => +acc + +item.quantity, 0), amount: v.reduce((acc, item) => +acc + +item.amount, 0) };
    }
    setDateMap(summary);
  }, [props.data]);

  let days = [];
  let tmpDt = moment(from, "YYYYMMDD");
  for (tmpDt; !tmpDt.isAfter(moment(to, "YYYYMMDD")); tmpDt.add(1, "day")) {
    days.push(moment(tmpDt, "YYYYMMDD"));
  }

  if (dateMap) {
    return (
      <Box>
        <Box my={2} sx={{ display: "flex", gap: "10px", overflowX: "auto", maxWidth: "85vw" }}>
          {days.map((dt, idx) => {
            const isSelected = date === dt.format("YYYY-MM-DD");
            return (
              <Link key={idx} onClick={() => props.onChange(dt.format("YYYY-MM-DD"))} underline="none" sx={{ cursor: "pointer", color: "inherit" }}>
                <Box
                  sx={{
                    border: isSelected ? "1px solid #78374a" : "1px solid #CCC",
                    backgroundColor: isSelected ? "rgba(120, 55, 74, 0.08)" : "inherit",
                    borderRadius: "4px",
                    padding: "7px",
                    minWidth: "100px",
                    textAlign: "center"
                  }}
                >
                  <Typography variant="h6" sx={{ color: isSelected ? "#78374a" : "inherit" }}>
                    {dt.format("ddd")}
                  </Typography>
                  <Typography variant="overline" color="textSecondary" display="block">
                    {dt.format("MMM DD")}
                  </Typography>
                  <Box mt={0.5} sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                    <Typography style={{ fontSize: ".8rem" }}>#{dateMap[dt.format("YYYY-MM-DD")]?.count || 0}</Typography>
                    {hasOwnerAccess(user.role) && (
                      <Typography style={{ fontSize: ".8rem" }}>
                        {currency(dateMap[dt.format("YYYY-MM-DD")]?.amount || 0, { precision: 0 }).format()}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Link>
            );
          })}
        </Box>
      </Box>
    );
  } else {
    return <></>;
  }
}
