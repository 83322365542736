import React, { useContext, useEffect, useState } from "react";
import { Alert, Backdrop, Box, Button, CircularProgress, Container, FormControl, FormHelperText, Paper, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { calculatePaymentSummary, getDefaultQuoteExpiration } from "../../common/util";
import FeatureAccess from "../../baker/components/FeatureAccess";
import LineItems from "../../common/component/lineitem/LineItems";
import CustomerDetailEntry from "../../customer/component/CustomerDetailEntry";
import PaymentTotal from "../component/PaymentTotal";
import QuoteFulfillmentEntry from "../../common/component/fulfillment/QuoteFulfillmentEntry";
import { UserContext } from "../../context/UserContext";
import BDateTimePicker from "../../common/input/BDateTimePicker";
import moment from "moment";

export default function Invoice(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [customer, setCustomer] = useState("");
  const [fulfillment, setFulfillment] = useState({
    date: "",
    option: { pickup: {}, delivery: { includeFees: user.config?.transferFees === "false" ? false : true } }
  });
  const [exdate, setExdate] = useState();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let arr = [];
    if (!fulfillment.date) {
      arr.push("Date is missing");
    }
    if (!fulfillment.address) {
      if (fulfillment.type === "pickup") {
        arr.push("Pickup Location is missing");
      } else if (fulfillment.type === "delivery") {
        arr.push("Delivery Address is missing");
      }
    }
    if (!fulfillment.option?.delivery?.type) {
      arr.push("Delivery option is missing");
    }
    if (!customer.name) {
      arr.push("Customer Name is missing");
    }
    if (!customer.phone) {
      arr.push("Phone is missing");
    }

    return arr;
  };

  useEffect(() => {
    setExdate(getDefaultQuoteExpiration(fulfillment.date));
  }, [fulfillment.date]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const mode = e.nativeEvent?.submitter?.name === 'save' ? 'save' : "send";
    const arr = validate();

    if (arr.length > 0) {
      setErrors(arr);
    } else {
      setLoading(true);

      const payload = {
        items,
        fulfillment,
        customer,
        exdate,
        mode
      };

      bkstApi
        .post(`/my/quote`, payload)
        .then((res) => {
          navigate(`/quote/${res.data.quoteId}`);
        })
        .catch((e) => {
          setErrors([`Error: ${e.response?.data?.message}`]);
          setLoading(false);
        });
    }
  };

  const paymentSummary = calculatePaymentSummary(items, fulfillment.deliveryFee);

  return (
    <Container maxWidth="sm">
      <FeatureAccess plan={["starter", "professional", "premium"]} />
      <form onSubmit={handleSubmit}>
        <Box my={6}>
          <Box mb={4} align="center">
            <Typography variant="h3" gutterBottom>
              Send Invoice
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Use this for quotes requested via phone, email, social media, or in-store. Includes automated follow-ups, balance payment, reviews and
              more!
            </Typography>
          </Box>
          <Box my={2}>
            <LineItems items={items} onChange={(arr) => setItems(arr)} />
            {items.length > 0 && (
              <Box>
                <Box my={4}></Box>
                <Typography variant="overline" color="textSecondary">
                  FULFILLMENT
                </Typography>
                <Paper elevation={0}>
                  <Box px={2} pt={4} pb={2}>
                    <QuoteFulfillmentEntry fulfillment={fulfillment} onChange={(o) => setFulfillment(o)} />
                  </Box>
                </Paper>
                <Box my={4}></Box>
                <Typography variant="overline" color="textSecondary">
                  CUSTOMER
                </Typography>
                <Paper elevation={0}>
                  <Box px={2} pt={3} pb={1}>
                    <CustomerDetailEntry customer={customer} onChange={(o) => setCustomer(o)} />
                  </Box>
                </Paper>
                <Box my={4}></Box>
                <Typography variant="overline" color="textSecondary">
                  PAYMENT SUMMARY
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    <PaymentTotal payment={paymentSummary} />
                  </Box>
                </Paper>
                <Box my={4} />
                <FormControl variant="outlined" fullWidth>
                  <BDateTimePicker
                    value={exdate || null}
                    label="Order By"
                    onChange={(d) => setExdate(d)}
                    disablePast
                    required
                    autoOk
                    maxDateTime={(fulfillment.date && moment(fulfillment.date).endOf("day").toDate()) || null}
                  />
                  <FormHelperText>{`Quote expires ${moment(`${exdate}`).fromNow()}`}</FormHelperText>
                </FormControl>
                {errors && errors.length > 0 && (
                  <Box my={2}>
                    <Alert severity="error">{errors.join(". ")}</Alert>
                  </Box>
                )}
                <Box my={4} sx={{ display: "flex", gap: "15px" }}>
                  <Button name="save" fullWidth type="submit" variant="outlined" color="secondary">
                    Save Draft
                  </Button>
                  <Button name="send" fullWidth type="submit" variant="contained" color="primary">
                    Send Invoice
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Backdrop open={loading} style={{ zIndex: "1000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </form>
    </Container>
  );
}
