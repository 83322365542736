import React from "react";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import Images from "../../request/components/Images";
import Invoices from "../component/Invoices";
import LineItem from "../../common/component/lineitem/LineItem";
import HTMLViewer from "../../common/component/HTMLViewer";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";

export default function OrderDetails(props) {
  const { order } = props;
  const details = order.details;

  if (order.src === "direct" && order.items) {
    return (
      <Paper elevation={0}>
        <Box px={2} py={2}>
          {order.items.map((item, idx) => (
            <Box key={idx} mb={1}>
              {item.total ? <Box py={1}><LineItemV2 item={item} view="min" immutablePrice={true} /></Box> : <LineItem item={item} view="min" />}
            </Box>
          ))}
          {order.invoice && <Invoices order={order} />}
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper elevation={0}>
        <Box px={2} py={4}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Images pics={details.pics} allowDownload={props.allowDownload} />
            </Grid>
            <Grid item sm={8} xs={12}>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <Typography variant="overline" color="textSecondary" display="block">
                    TITLE
                  </Typography>
                  <Typography variant="body2" display="block">
                    {details.title}
                  </Typography>
                </Grid>
                {details.size && (
                  <Grid item sm={6} xs={6}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      SIZE
                    </Typography>
                    <Typography variant="body2" display="block">
                      {`${details.shape || ""} ${details.size}`}
                    </Typography>
                  </Grid>
                )}
                <Grid item sm={6} xs={6}>
                  <Typography variant="overline" color="textSecondary" display="block">
                    SERVINGS
                  </Typography>
                  <Typography variant="body2" display="block">
                    {details.servings || `Not specified`}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Typography variant="overline" color="textSecondary" display="block">
                    FLAVOR
                  </Typography>
                  <Typography variant="body2" display="block">
                    {details.flavor || `Not specified`}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <Typography variant="overline" color="textSecondary" display="block">
                    FILLING
                  </Typography>
                  <Typography variant="body2" display="block">
                    {details.filling || `Not specified`}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography variant="overline" color="textSecondary" display="block">
                    FROSTING
                  </Typography>
                  <Typography variant="body2" display="block">
                    {details.frosting || `Not specified`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="overline" color="textSecondary" display="block">
                    INSCRIPTION
                  </Typography>
                  <Typography variant="body2" display="block">
                    {details.inscription || `Not specified`}
                  </Typography>
                </Grid>
                {details.tags && details.tags.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      TAGS
                    </Typography>
                    {details.tags.map((e, idx) => (
                      <Chip key={idx} size="small" label={e} />
                    ))}
                  </Grid>
                )}
                {details.decor && details.decor.length > 2 && (
                  <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary" display="block">
                      NOTES
                    </Typography>
                    <HTMLViewer content={details.decor} style={{ fontSize: ".85rem" }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {order.invoice && <Invoices order={order} />}
        </Box>
      </Paper>
    );
  }
}
