import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, Container, FormControl, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import LineItems from "../../common/component/lineitem/LineItems";
import BDateTimePicker from "../../common/input/BDateTimePicker";
import { getDefaultQuoteExpiration, getDefaultQuoteExtension } from "../../common/util";
import QuoteFulfillmentEntry from "../../common/component/fulfillment/QuoteFulfillmentEntry";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import BakerChat from "../../quote/component/action/BakerChat";

export default function EditQuote(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [fulfillment, setFulfillment] = useState({ type: "pickup" });
  const [exdate, setExdate] = useState("");

  const [errors, setErrors] = useState([]);

  const { reqId } = useParams();
  const [request, setRequest] = useState("");
  const [quote, setQuote] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fulfillment.date) {
      if (!exdate || quote.status === "pending") {
        setExdate(getDefaultQuoteExpiration(fulfillment.date));
      } else if (quote.status === "expired" || moment(exdate).diff(moment(), "minutes") <= 30) {
        setExdate(getDefaultQuoteExtension(fulfillment.date));
      }
    }
  }, [fulfillment.date]);

  useEffect(() => {
    setLoading(true);

    bkstApi(`/my/quote/${reqId}`).then((res) => {
      setRequest(res.data.request);
      setQuote(res.data.quote);

      setItems(res.data.quote.items);
      setFulfillment(res.data.quote.fulfillment);

      setExdate(res.data.quote.exdate);
      setLoading(false);
    });
  }, [reqId, location.key]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const mode = e.nativeEvent?.submitter?.name === "save" ? "save" : "send";

    let payload = {
      mode,
      items,
      fulfillment,
      exdate,
      appVersion: process.env.REACT_APP_VERSION
    };

    bkstApi
      .put(`/my/quote/${reqId}`, payload)
      .then((res) => {
        setLoading(true);
        navigate(`/quote/${reqId}`);
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  };

  if (loading) {
    return <LinearProgress />;
  } else if (request && quote.items) {
    return (
      <Box sx={{ minHeight: "100vh", backgroundColor: "#FBEEE6" }}>
        <Container maxWidth="sm">
          <Box py={6}>
            <form onSubmit={handleSubmit}>
              <Box textAlign={"center"}>
                <Typography variant="h3" gutterBottom>
                  Edit Quote
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Complete all your changes. Then tap the Send Quote button to save changes & notify the customer
                </Typography>
              </Box>
              <Box my={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
                    {request.src === "direct" ? (
                      <CustomerContactInfoButton
                        customer={{
                          firstName: request.firstName,
                          lastName: request.lastName,
                          name: request.name,
                          phone: request.phone,
                          email: request.email
                        }}
                        src={request.src}
                      />
                    ) : (
                      <CustomerContactInfoButton
                        customer={{
                          firstName: request.firstName,
                          lastName: request.lastName,
                          name: request.name
                        }}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: "4px", alignItems: "center", flexGrow: "1", justifyContent: "flex-end" }}>
                    {request.src === "direct" && <BakerChat request={request} quote={quote} status={quote.status} />}
                  </Box>
                </Box>
                <LineItems items={items} onChange={(arr) => setItems(arr)} />
                {items.length > 0 && (
                  <Box>
                    <Box my={4}></Box>
                    <Typography variant="overline" color="textSecondary">
                      SHIPPING
                    </Typography>
                    <Paper elevation={0}>
                      <Box px={2} pt={3} pb={2}>
                        <QuoteFulfillmentEntry fulfillment={fulfillment} onChange={(o) => setFulfillment(o)} />
                      </Box>
                    </Paper>
                    <Box mt={4} mb={2}>
                      <Box>
                        <FormControl variant="outlined" fullWidth>
                          <BDateTimePicker
                            value={exdate || null}
                            label="Expiration"
                            onChange={(d) => setExdate(d)}
                            disablePast
                            required
                            autoOk
                            maxDateTime={(fulfillment.date && moment(fulfillment.date).endOf("day").toDate()) || null}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    {errors && errors.length > 0 && (
                      <Box my={2}>
                        <Alert severity="error">{errors.join(". ")}</Alert>
                      </Box>
                    )}
                  </Box>
                )}
                <Box my={4}>
                  <Box sx={{ display: "flex", gap: "15px" }}>
                    {quote?.status === "pending" && (
                      <Button
                        name="save"
                        color="secondary"
                        fullWidth
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={items.length === 0}
                      >
                        Save Draft
                      </Button>
                    )}
                    <Button name="send" fullWidth type="submit" variant="contained" color="primary" disabled={items.length === 0}>
                      Send Quote
                    </Button>
                  </Box>
                  <Box my={2} textAlign={"center"}>
                    <Button size="small" color="secondary" onClick={() => navigate(`/quote/${reqId}`)}>
                      Cancel Changes
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    );
  }
}
