import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Paper, Typography, Divider, AlertTitle } from "@mui/material";
import bkstApi from "../../api";
import moment from "moment";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@mui/material";
import FeatureAccess from "../../baker/components/FeatureAccess";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import StaticPage from "../../common/StaticPage";

export default function BakerLoan(props) {
  const [loan, setLoan] = useState();
  const [repayments, setRepayments] = useState();

  const [status, setStatus] = useState("loading");

  useEffect(() => {
    bkstApi(`/my/loan`).then((res) => {
      setLoan(res.data.loan);
      if (res.data.loan.repayment) {
        const arr = Object.values(res.data.loan.repayment).sort((o1, o2) => (o1.ts > o2.ts ? -1 : 1));
        setRepayments(arr);
      }

      setStatus("");
    });
  }, []);

  const apply = () => {
    bkstApi.put(`/my/loan/${loan.id}/apply`, {}).then((res) => {
      setStatus("apply");
    });
  };

  if (status === "loading") {
    return <LinearProgress />;
  } else if (status == "apply") {
    return (
      <StaticPage
        title="Application Submitted"
        desc="We will review your application with the next 24 hours. Once approved, funds will be deposited to your bank account within the next 48 hours."
        icon={TaskAltOutlinedIcon}
      ></StaticPage>
    );
  } else if (loan?.status === "active") {
    return (
      <Container maxWidth="sm">
        <FeatureAccess plan={["professional", "premium"]} />
        <Box my={5}>
          <Box mb={2}>
            <Typography variant="h4">Cash Advance Details</Typography>
          </Box>
          <Box px={2} py={3} style={{ backgroundColor: "#ECECEC" }}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={6}>
                <Typography variant="overline" color="textSecondary">
                  CASH ADVANCE AMOUNT
                </Typography>
                <Typography variant="body1">{currency(loan.loanAmount).format()}</Typography>
              </Grid>
              <Grid item sm={4} xs={6}>
                <Typography variant="overline" color="textSecondary">
                  FEE / INTEREST
                </Typography>
                <Typography variant="body1">{currency(loan.fee).format()}</Typography>
              </Grid>
              <Grid item sm={4} xs={6}>
                <Typography variant="overline" color="textSecondary">
                  TOTAL AMOUNT DUE
                </Typography>
                <Typography variant="body1">{currency(loan.total).format()}</Typography>
              </Grid>
              <Grid item sm={4} xs={6}>
                <Typography variant="overline" color="textSecondary">
                  PERIOD
                </Typography>
                <Typography variant="body1">{loan.period} days</Typography>
              </Grid>
              <Grid item sm={4} xs={6}>
                <Typography variant="overline" color="textSecondary">
                  AMOUNT REPAID
                </Typography>
                <Typography variant="body1">{currency(+loan.total - +loan.balance).format()}</Typography>
              </Grid>
              <Grid item sm={4} xs={6}>
                <Typography variant="overline" color="textSecondary">
                  OUTSTANDING BALANCE
                </Typography>
                <Typography variant="body1">{currency(loan.balance).format()}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {repayments && (
          <Box my={5}>
            <Box mb={2}>
              <Typography variant="h5" gutterBottom>
                Repayment Activity
              </Typography>
              <Typography color="textSecondary">{loan.repaymentRate || 20}% of each transaction goes towards cash advance repayment</Typography>
            </Box>
            <Box p={2} style={{ border: "1px solid #EEE", backgroundColor: "#fff" }}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="overline" color="textSecondary">
                    DATE
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="overline" color="textSecondary">
                    REPAYMENT AMOUNT
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="overline" color="textSecondary">
                    DETAILS
                  </Typography>
                </Grid>
              </Grid>
              <Box mt={1} mb={2}>
                <Divider />
              </Box>
              {repayments.map((value, idx) => {
                return (
                  <Grid key={idx} container spacing={2} mb={2} alignItems="center">
                    <Grid item xs={3}>
                      <Typography variant="body2">{moment(value.ts).format("MMM DD")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{currency(value.amount).format()}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button fullWidth size="small" color="secondary" variant="outlined" component={RouterLink} to={`/order/${value.orderId}`}>
                        view
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        )}
      </Container>
    );
  } else if (loan?.status === "offer" || loan?.status === "review" || loan?.status === "approved") {
    return (
      <Container maxWidth="sm">
        <FeatureAccess plan={["professional", "premium"]} />
        <Box my={4}>
          <Typography variant="h3">Working Capital</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Funding to grow your business
          </Typography>
        </Box>
        <Box my={4}>
          <Box my={2}>
            <Box my={2}>
              {loan?.status === "offer" && (
                <Alert severity="success">
                  <AlertTitle>You qualify for a cash advance from BakeStreet!</AlertTitle>Eligibility is evaluated periodically and
                  considers your sales volume for the past 6 months.
                </Alert>
              )}
              {loan?.status === "review" && (
                <Alert severity="info">
                  <AlertTitle>Your cash advance application is being reviewed</AlertTitle>Once approved, funds will be deposited to your bank account within
                  the next 48 hours.
                </Alert>
              )}
              {loan?.status === "approved" && (
                <Alert severity="success">
                  <AlertTitle>Your cash advance application has been approved</AlertTitle>The funds have been transferred & will be included in your next
                  payout. The automatic repayment will begin once the funds have been credited to your bank account.
                </Alert>
              )}
            </Box>
            <Typography variant="overline" color="textSecondary">
              OFFER DETAILS
            </Typography>
            <Paper elevation={0}>
              <Box px={2} py={4}>
                <Box>
                  <Box mb={0.5} sx={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Typography variant="h2">{currency(loan.loanAmount, { precision: 0 }).format()}</Typography>
                    <Typography variant="overline">CASH ADVANCE AMOUNT</Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">
                    There's no personal credit check and no complicated application process. Eligibility is based on factors like your payment volume
                    and history on BakeStreet.
                  </Typography>
                </Box>
                <Box my={4}>
                  <Box mb={0.5} sx={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Typography variant="h4" display="inline">
                      {currency(loan.fee, { precision: 0 }).format()}
                    </Typography>
                    <Typography variant="overline">FIXED FEE</Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">
                    Pay one flat fee that never changes. There are no compounding interest charges, collateral obligations, or late fees.
                  </Typography>
                </Box>
                <Box>
                  <Box mb={0.5} sx={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Typography variant="h4" display="inline">
                      {loan.repaymentRate}%
                    </Typography>
                    <Typography variant="overline">REPAYMENT RATE</Typography>
                  </Box>
                  <Typography variant="body1" color="textSecondary">
                    Repayment is fully automated and adjusts to your daily sales. BakeStreet deducts a fixed percentage of your sales until the total
                    owed is repaid.
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Box py={2}>
              {loan?.status === "offer" && (
                <Button variant="contained" fullWidth onClick={apply}>
                  Apply
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="sm">
        <FeatureAccess plan={["professional", "premium"]} />
        <Box my={4} textAlign={"center"}>
          <Typography variant="h3" gutterBottom>
            Working Capital
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Get funding to grow your business
          </Typography>
        </Box>
        <Box my={2}>
          <Alert severity="info">
            <AlertTitle severity="error">You do not currently qualify for a cash advance</AlertTitle>Eligibility is evaluated periodically and considers
            your sales volume for the past 6 months.
          </Alert>
        </Box>
        <Typography variant="overline" color="textSecondary">
          HOW IT WORKS
        </Typography>
        <Paper my={2} elevation={0}>
          <Box px={2} py={4}>
            <Typography variant="h5" gutterBottom>
              No lengthy application
            </Typography>
            <Typography variant="body1" color="textSecondary">
              There's no personal credit check and no complicated application process. Eligibility is based on factors like your payment volume and
              history on BakeStreet.
            </Typography>
            <Box my={4}>
              <Typography variant="h5" gutterBottom>
                Get funds quickly
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Applications are typically approved within 2 business days and funds arrive the next business day.
              </Typography>
            </Box>
            <Box my={4}>
              <Typography variant="h5" gutterBottom>
                One flat fee
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Pay one flat fee that never changes. There are no compounding interest charges, collateral obligations, or late fees.
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom>
              Pay when you get paid
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Repayment is fully automated and adjusts to your daily sales. BakeStreet deducts a fixed percentage of your sales until the total owed
              is repaid.
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
}
