import React, { useEffect, useState } from "react";
import { Box, Container, Chip, LinearProgress, Paper, Typography, Alert, Avatar } from "@mui/material";
import bkstApi from "../../api";
import moment from "moment";
import PicUpload from "../../common/PicUpload";

export default function MyStaff(props) {
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(true);

  const sortStaff = (o1, o2) => {
    let rank = o2.role?.localeCompare(o1.role);
    if (rank === 0) {
      rank = o2.firstName.localeCompare(o1.firstName);
    }
    return rank;
  };

  useEffect(() => {
    setLoading(true);
    bkstApi(`/my/staff`).then((res) => {
      setStaff(res.data.staff.sort(sortStaff));
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box>
          <Box my={6}>
            <Typography variant="h1" gutterBottom>
              Staff
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Manage access for your staff members
            </Typography>
            <Box my={2}>
              <Alert severity="info">For any changes, please contact BakeStreet using the Online Chat option</Alert>
            </Box>
          </Box>
          <Box>
            {staff.map((o) => {
              return (
                <Box my={4} key={o.id}>
                  <Paper elevation={0}>
                    <Box p={2} sx={{ display: "flex", gap: "10px" }}>
                      <img
                        src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${o.uid}.webp`}
                        style={{ width: "75px", height: "75px", borderRadius: "4px" }}
                      />
                      <Box>
                        <Typography variant="h6">
                          {o.firstName} {o.lastName}
                        </Typography>
                        <Chip size="small" label={o.role} />
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Container>
    );
  }
}
