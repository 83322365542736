import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Avatar, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { auth } from "../../firebase";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { STORAGE_BUCKET } from "../../common/constants";
import { UserContext } from "../../context/UserContext";

const menuId = "user-menu";
export default function UserLoggedInButton(props) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    auth
      .signOut()
      .then(() => {
        setUser("");
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <span>
      <Avatar src={`${STORAGE_BUCKET}/img/u/${user.uid}.webp`} onClick={handleProfileMenuOpen} style={{ cursor: "pointer" }}></Avatar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </span>
  );
}
