import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import Images from "../../request/components/Images";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { QUOTE_TAGS } from "../../common/constants";
import { Autocomplete } from "@mui/material";
import { Alert } from "@mui/material";
import ProductAttributes from "../../common/component/ProductAttributes";
import { useParams } from "react-router-dom";
import LineItem from "../../common/component/lineitem/LineItem";
import QuillRTE from "../../common/component/QuillRTE";
import LineItemEntryPopup from "../../common/component/lineitem/LineItemEntryPopup";
import LineItemEntryPopupV2 from "../../common/component/lineitem/LineItemEntryPopupV2";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";

export default function OrderDetailsEntry(props) {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [order, setOrder] = useState("");
  const [items, setItems] = useState("");

  const [title, setTitle] = useState("");
  const [pics, setPics] = useState("");
  const [product, setProduct] = useState("");
  const [flavor, setFlavor] = useState("");
  const [filling, setFilling] = useState("");
  const [tags, setTags] = useState([]);
  const [decor, setDecor] = useState("");
  const [inscription, setInscription] = useState("");
  const [status, setStatus] = useState();
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/order/${orderId}`).then((res) => {
      setOrder(res.data);
      setItems(res.data.items);

      const details = res.data.details;
      setTitle(details.title);
      setPics(details.pics);
      setFlavor(details.flavor);
      setFilling(details.filling);
      setTags(details.tags || []);
      setDecor(details.decor || "");
      setInscription(details.inscription);
      setStatus(res.data.status);
      setProduct({
        productType: details.productType || "",
        shape: details.shape || "",
        size: details.size || "",
        servings: details.servings || "",
        frosting: details.frosting || ""
      });
      setLoading(false);
    });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const details = {
      title,
      pics,
      ...product,
      flavor,
      filling,
      tags,
      decor,
      inscription
    };
    bkstApi.put(`/my/order/${orderId}/details`, { details, consent }).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  const handleMultiItemSubmit = (e) => {
    e.preventDefault();

    bkstApi.put(`/my/order/${orderId}/items`, { items, consent }).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else if (order.src === "direct" && order.items) {
    return (
      <Box sx={{ minHeight: "100vh", backgroundColor: "#FBEEE6" }}>
        <Container maxWidth="sm">
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Box py={6}>
                <Box mb={4} textAlign={"center"}>
                  <Typography variant="h3" gutterBottom>
                    Edit Line Items
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Make changes to one more more item(s) below, then click the button below to save all your changes
                  </Typography>
                </Box>
                <Box>
                  <form onSubmit={handleMultiItemSubmit}>
                    {items.map((o, idx) => (
                      <Grid key={idx} container spacing={1} mb={2}>
                        <Grid item sm={11} xs={10}>
                          <Box key={idx}>
                            <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                              <Paper elevation={0} sx={{ flexGrow: "1" }}>
                                <Box p={2}>{o.total ? <LineItemV2 item={o} immutablePrice={true} /> : <LineItem item={o} />}</Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                          {o.total ? (
                            <LineItemEntryPopupV2
                              item={o}
                              immutablePrice={true}
                              onChange={(o) => {
                                let arr = [...items];
                                arr[idx] = o;
                                setItems(arr);
                              }}
                            />
                          ) : (
                            <LineItemEntryPopup
                              item={o}
                              onChange={(o) => {
                                let arr = [...items];
                                arr[idx] = o;
                                setItems(arr);
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    <Grid container spacing={1} mb={4}>
                      <Grid item sm={11} xs={10}>
                        <Box>
                          <LineItemEntryPopupV2
                            idx={items.length}
                            mode="add"
                            onChange={(o) => {
                              let arr = [...items];
                              arr.push(o);
                              setItems(arr);
                            }}
                          />
                        </Box>
                        <Box mt={4} sx={{ display: "flex", gap: "15px" }}>
                          <Button variant="outlined" color="secondary" onClick={() => navigate(`/order/${orderId}`)} fullWidth>
                            CANCEL
                          </Button>
                          <Button type="submit" variant="contained" color="primary" fullWidth>
                            SAVE CHANGES
                          </Button>
                        </Box>
                        <Box my={2} textAlign={"center"}>
                          <Typography variant="caption" color="textSecondary">
                            Customer will be notified when order changes are saved
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  } else {
    return (
      <Container maxWidth="sm">
        <Box py={6}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">EDIT ORDER</Typography>
            </Grid>
          </Grid>
          <Paper>
            <Box px={3} pt={3} pb={3}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item sm={12} xs={6}>
                    <Box mb={2}>
                      <Images edit={true} pics={pics} onChange={(pics) => setPics(pics)} folder={`img/o/${orderId}`} />
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="title"
                      value={title}
                      label="Title"
                      name="title"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ProductAttributes value={product} onChange={(o) => setProduct(o)} required={true} />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="flavor"
                      value={flavor}
                      label="Flavor"
                      name="flavor"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setFlavor(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="filling"
                      value={filling}
                      label="Filling"
                      name="filling"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setFilling(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="inscription"
                      value={inscription}
                      label="Inscription"
                      name="inscription"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setInscription(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      value={tags}
                      onChange={(e, val) => setTags(val)}
                      options={QUOTE_TAGS}
                      filterSelectedOptions
                      renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                      multiple
                      freeSolo
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <QuillRTE value={decor} onChange={(html) => setDecor(html)} />
                  </Grid>
                  {order.paymentType != "offline" && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        className="consent"
                        control={
                          <Checkbox
                            required={status === "confirmed"}
                            checked={consent}
                            onChange={(e) => setConsent(e.target.checked)}
                            name="consent"
                            color="secondary"
                          />
                        }
                        label="I've discussed these changes with the customer and we're in agreement"
                      />
                      {consent && <Alert severity="info">Customer will be notified when you save these changes</Alert>}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Button variant="outlined" color="secondary" onClick={() => navigate(`/order/${orderId}`)} fullWidth>
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained" color="primary" fullWidth>
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
