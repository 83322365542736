import React from "react";
import currency from "currency.js";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";

export default function PaymentDue(props) {
  const { total, due, date } = props;

  return (
    <Box>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography variant="overline" color="textSecondary">
              PAYMENT DUE (USD)
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <Typography variant="overline" color="textSecondary">
              {currency(due).format()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {+due < +total && (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Typography variant="overline" color="textSecondary">
                Balance due
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Typography variant="overline" color="textSecondary">
                {currency(total - due).format()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
