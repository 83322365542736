import React, { useContext } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { STORAGE_BUCKET, IMG_PLACEHOLDER, bakeStreetSquareLogo } from "../../common/constants";
import { Link as RouterLink } from "react-router-dom";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { getCustomerName, hasManagerAccess } from "../../common/util";
import OrderProgress from "../../order/component/OrderProgress";
import OrderPrint from "../screen/OrderPrint";
import { UserContext } from "../../context/UserContext";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import currency from "currency.js";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";

export default function BakerOrderSummary(props) {
  const { order, readOnly } = props;
  const { user } = useContext(UserContext);

  const printed = order?.activity?.print;
  const lastPrintTs = printed && printed[printed.length - 1].ts;

  let image = `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`;
  if (order.items && order.items.length > 0) {
    for (const item of order.items) {
      if (item.images && item.images.length > 0) {
        image = item.images[0];
        break;
      }
    }
  } else if (order.details && order.details.pics) {
    image = order.details.pics[0];
  }

  let isManager = hasManagerAccess(user.role);

  return (
    <Box mb={4}>
      <Paper elevation={0}>
        <Box px={2} pt={2} pb={1}>
          <Grid container spacing={2}>
            <Grid item sm={3} xs={4} sx={{}}>
              <img src={image} style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} />
            </Grid>
            <Grid item sm={9} xs={8}>
              <Typography variant="h6" gutterBottom>
                {getCustomerName(order.customer)}
              </Typography>
              <Box style={{ color: "#888" }}>
                <Box>
                  <Typography
                    variant="overline"
                    style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                  >
                    <EventOutlinedIcon style={{ fontSize: "1.2rem" }} />
                    {moment(order.fulfillment.date).format("ddd, MMM D")}
                    <span style={{ color: "#CCC" }}>{` ● `}</span>
                    {order.fulfillment.time}
                  </Typography>
                </Box>
                {order.fulfillment.type === "delivery" && (
                  <Box>
                    <Typography
                      variant="overline"
                      style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                    >
                      <LocalShippingOutlinedIcon style={{ fontSize: "1.2rem" }} />
                      {order.fulfillment.type}
                      <span style={{ color: "#CCC" }}>{` ● `}</span>
                      {order.fulfillment.provider ? order.fulfillment.provider.name : "In-House"}
                    </Typography>
                  </Box>
                )}
                {order.fulfillment.type === "pickup" && (
                  <Box>
                    <Typography
                      variant="overline"
                      style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                    >
                      <LocalMallOutlinedIcon style={{ fontSize: "1.2rem" }} />
                      {order.fulfillment.type}
                      <span style={{ color: "#CCC" }}>{` ● `}</span>
                      {order.fulfillment.location ? order.fulfillment.location.street : order.fulfillment.address.split(",")[0]}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography
                    variant="overline"
                    style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                  >
                    <ReceiptLongOutlinedIcon style={{ fontSize: "1.2rem" }} />
                    {isManager && `${currency(order.src === "mkt" ? order.payment.bakerTotal : order.payment.total).format()}`}
                    {isManager && <span style={{ color: "#CCC" }}>{` ● `}</span>}
                    {order.items?.length} item(s)
                  </Typography>
                </Box>
                {props.showTs && (
                  <Typography
                    variant="overline"
                    style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                  >
                    <AccessTimeOutlinedIcon style={{ fontSize: "1.2rem" }} />
                    Ordered {moment(order.ts._seconds * 1000).fromNow()}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!readOnly && (
          <Box px={2} pb={1}>
            <Grid container alignItems="center">
              <Grid item sm={3} xs={4}>
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  {isManager && order.status === "confirmed" && <OrderProgress progress={order.progress} orderId={order.id} />}
                  {(order.status === "fulfilled" || order.status === "completed") && <TaskAltIcon sx={{ color: "#16a085" }} />}
                  {order.status === "canceled" && <BlockOutlinedIcon sx={{ color: "#c0392b" }} />}
                  {isManager && printed && lastPrintTs && <OrderPrint order={order} />}
                </Box>
              </Grid>
              <Grid item sm={9} xs={8}>
                <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                  {isManager && order.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                  {order.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                  <Button
                    color={order.status === "pending_review" ? "warning" : "primary"}
                    disableElevation
                    variant="contained"
                    to={`/order/${order.id}`}
                    component={RouterLink}
                  >
                    {order.status === "pending_review" ? "CONFIRM ORDER" : "VIEW ORDER"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
