import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  DialogActions
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export default function FlavorInputList(props) {
  const [value, setValue] = useState(props.value);

  const onChange = (updated, orig) => {
    const newData = { ...value };
    if (orig) {
      delete newData[orig.name.toLowerCase()];
    }

    if (updated) {
      newData[updated.name.toLowerCase()] = updated;
    }
    setValue(newData);
    props.onChange(newData);
  };

  return (
    <Paper elevation={3}>
      <Box p={3}>
        <Box>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Typography variant="h5">{props.type}s</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Entry category={props.type} variant="outlined" cta="Add" value="" onChange={onChange} />
            </Grid>
          </Grid>
          <Box mt={2} mb={3}>
            <Divider />
          </Box>
        </Box>
        {value &&
          Object.entries(value)
            .sort()
            .map(([key, value]) => {
              return <Row key={key} value={value} category={props.type} onChange={onChange} />;
            })}
      </Box>
    </Paper>
  );
}

function Row(props) {
  const onChange = (data) => {
    props.onChange(data, props.value);
  };

  return (
    <Box my={1}>
      <Box>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs={10}>
            <Typography variant="body1" style={{ wordWrap: "anywhere" }}>
              {props.value.name}
              {props.value.type === "premium" && <StarIcon style={{ fontSize: "1rem", paddingLeft: "3px" }} />}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {props.value.type === "premium" ? `Premium option. $${props.value.fee} surcharge. ` : ""}
              {props.value.desc}
            </Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Entry cta="Edit" value={props.value} category={props.category} onChange={onChange} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function Entry(props) {
  const { color, variant, cta, category, value } = props;

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(value.name || "");
  const [type, setType] = useState(value.type || "std");
  const [fee, setFee] = useState(value.fee || "");
  const [desc, setDesc] = useState(value.desc || "");

  const submit = (e) => {
    e.preventDefault();
    props.onChange({ name, type, fee, desc });
    setOpen(false);
    //reset();
  };

  const handleDelete = () => {
    props.onChange("");
    setOpen(false);
    reset();
  };

  const reset = () => {
    setName("");
    setType("");
    setFee("");
    setDesc("");
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setFee("");
  };

  return (
    <span>
      <Button
        color={color || "primary"}
        variant={variant || "text"}
        size="small"
        onClick={() => setOpen(true)}
        disableElevation
        style={{ cursor: "pointer" }}
      >
        {cta}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={submit}>
          <DialogTitle>{`${cta} ${category}`}</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    value={name}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Name"
                    inputProps={{ maxLength: 40 }}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select value={type} labelId="type-label" label="Type" id="type" name="type" onChange={handleTypeChange}>
                      <MenuItem value="std">{`Standard ${category}`}</MenuItem>
                      <MenuItem value="premium">{`Premium ${category} (surcharge)`}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth required={type === "premium"} disabled={type === "std"}>
                    <InputLabel id="fee-label">Surcharge</InputLabel>
                    <Select value={fee} labelId="fee-label" label="Surcharge" id="fee" name="fee" onChange={(e) => setFee(e.target.value)}>
                      <MenuItem value="5">$5 extra</MenuItem>
                      <MenuItem value="10">$10 extra</MenuItem>
                      <MenuItem value="20">$20 extra</MenuItem>
                      <MenuItem value="30">$30 extra</MenuItem>
                      <MenuItem value="40">$40 extra</MenuItem>
                      <MenuItem value="50">$50 extra</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    name="desc"
                    value={desc}
                    variant="outlined"
                    fullWidth
                    id="desc"
                    label="Description (optional)"
                    inputProps={{ maxLength: 75 }}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={4}>
                {value && (
                  <Button onClick={handleDelete} style={{ color: "#e74c3c" }}>
                    Delete
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Box align="right">
                  <Button style={{ marginRight: "15px" }} onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </span>
  );
}
